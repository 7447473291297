/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import * as rdd from 'react-device-detect'; /* 39749 Api getting called multiple times */
// import Api from 'api';
import styles from './Welcome.module.css';
import { signinRedirectWelcome, signInUser } from '../../userService';
import store from '../../store';
// import { getIPAddress } from '../../api/captureLoggedInUserData'; /* 39749 Api getting called multiple times */

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleCreate: false,
      isLoading: true,
      /* 39749 Api getting called multiple times */
      // userInfo: {
      //   smashId: '',
      //   ipAddress: '',
      //   loggedInUserInformation: {
      //     userId: '',
      //     username: ''
      //   },
      //   connection: {
      //     downlink: 0,
      //     effectiveType: '',
      //     onchange: null,
      //     rtt: 0,
      //     saveData: false
      //   },
      //   geolocation: {
      //     cords: {
      //       accuracy: 0,
      //       altitude: null,
      //       altitudeAccuracy: null,
      //       heading: null,
      //       latitude: 0,
      //       longitude: 0,
      //       speed: null
      //     },
      //     timestamp: new Date()
      //   },
      //   userAgent: {
      //     browserName: '',
      //     browserVersion: '',
      //     deviceType: '',
      //     engineName: '',
      //     engineVersion: '',
      //     fullBrowserVersion: '',
      //     osName: '',
      //     osVersion: '',
      //     mobileModel: '',
      //     mobileVendor: ''
      //   }
      // },
      // isIpAddressSet: false,
      // isConnectionSet: false,
      // isGeolocationSet: false,
      // isUserAgentSet: false,
    };

    // User Story 26828: Enable login to handle saved password tool
    console.log(window.location.href);
    this.goToSmashListing = this.goToSmashListing.bind(this);
    // User Story 29403: Remove welcome screen dependence
  }

  async componentDidMount() {
    if (!window.location.href.includes('#id_token=')) this.login();
    await signInUser(store);
    console.log('Call SignIn');
  }

  // async componentDidMount() {
  //   /* 39749 Api getting called multiple times */
  //   try {
  //     const response = await getIPAddress();
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       userInfo: {
  //         ...prevState.userInfo,
  //         ipAddress: response?.data?.IPv4
  //       },
  //       isIpAddressSet: true
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isIpAddressSet: true
  //     }));
  //   }

  //   const success = (pos) => {
  //     const {
  //       accuracy,
  //       altitude,
  //       altitudeAccuracy,
  //       heading,
  //       latitude,
  //       longitude,
  //       speed
  //     } = pos.coords;
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       userInfo: {
  //         ...prevState.userInfo,
  //         geolocation: {
  //           ...prevState.userInfo.geolocation,
  //           cords: {
  //             ...prevState.userInfo.geolocation.cords,
  //             accuracy,
  //             altitude,
  //             altitudeAccuracy,
  //             heading,
  //             latitude,
  //             longitude,
  //             speed
  //           },
  //           timestamp: new Date(pos.timestamp)
  //         },
  //       },
  //       isGeolocationSet: true
  //     }));
  //   };

  //   const error = (err) => {
  //     console.warn(`ERROR(${err.code}): ${err.message}`);
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isGeolocationSet: true
  //     }));
  //   };

  //   /* 39749 Api getting called multiple times */
  //   try {
  //     if (navigator.geolocation) { navigator.geolocation.getCurrentPosition(success, error); }
  //   } catch (error) {
  //     console.log('error on gelocation', error);
  //   }

  //   const { connection = {} } = window.navigator;

  //   this.setState((state) => ({
  //     ...state,
  //     userInfo: {
  //       ...state.userInfo,
  //       connection: {
  //         ...state.userInfo.connection,
  //         downlink: connection.downlink || 0,
  //         effectiveType: connection.effectiveType || '',
  //         onchange: connection.onchange || null,
  //         rtt: connection.rtt || 0,
  //         saveData: connection.saveData || false
  //       },
  //       userAgent: {
  //         ...state.userInfo.userAgent,
  //         browserName: rdd.browserName,
  //         browserVersion: rdd.browserVersion,
  //         deviceType: rdd.deviceType,
  //         engineName: rdd.engineName,
  //         engineVersion: rdd.engineVersion,
  //         fullBrowserVersion: rdd.fullBrowserVersion,
  //         osName: rdd.osName,
  //         osVersion: rdd.osVersion,
  //         mobileModel: rdd.mobileModel,
  //         mobileVendor: rdd.mobileVendor
  //       }
  //     },
  //     isConnectionSet: true,
  //     isUserAgentSet: true
  //   }));
  // }

  // static async getDerivedStateFromProps() {
  //   await signInUser(store);
  //   console.log('Call SignIn');
  // }

  async componentDidUpdate(prevProps) {
    const { user } = this.props;

    /* 39749 Api getting called multiple times */
    // const {
    //   isIpAddressSet, isConnectionSet, isGeolocationSet, isUserAgentSet
    // } = this.state;

    /* 39749 Api getting called multiple times */
    // if (isIpAddressSet && isConnectionSet && isGeolocationSet && isUserAgentSet) {
    //   const { userInfo } = this.state;
    //   await Api.createCapturedUserInformation(userInfo);
    //   this.goToSmashListing();
    // }

    if (user !== prevProps.user) {
      this.setState({ ...this.state, isLoading: true });
      this.props.setGlobalUserProps();
      this.goToSmashListing();
    }
  }

  setToggleCreate(toggleCreate) {
    this.setState({ toggleCreate });
  }

  goToSmashListing() {
    const { history } = this.props;
    history.push('/SmashListing');
  }

  login() {
    this.setState({ ...this.state, isLoading: true });
    signinRedirectWelcome();
  }

  render() {
    // User Story 29403: Remove welcome screen dependence
    const { isLoading } = this.state;
    return (
      // User Story 29403: Remove welcome screen dependence
      <div className={styles.wrapperFlexCenter}>
        <div className={styles.welcomeBlock}>
          {isLoading ? (
            <div className="backdroploader">
              <div className="loader"></div>
            </div>
          ) : '' }
        </div>
      </div>
    );
  }
}
Welcome.defaultProps = { user: {} };
Welcome.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  setGlobalUserProps: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
