/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { copyToClipBoard } from 'utils/Toast';
import Done from 'assets/images/Done.svg';
import axios from 'axios';
import styles from './Create.module.css';
import { signInUser } from '../../userService';
import store from '../../store';
import AppUri from '../../envConfig';
import { userSignedOut } from '../../actions/authActions';
import cookieService from '../../cookieService';

const api = axios.create({
  baseURL: AppUri.APP_RANKSMASH_API_BASE_URI,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
});

const button = (
  <div className="flex">
    <span className="width80">Link Copied</span>
    <button
      type="button"
      className="closeButton"
    >
      <span><i className={cx('closeicon', 'material-icons')}>clear</i></span>
    </button>
  </div>
);

class ShareSmash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smashId: 0,
      settings: [],
    };
  }

  static async getDerivedStateFromProps() {
    await signInUser(store);
    console.log('Call SignIn');
  }

  componentDidUpdate(prevProps) {
    console.log('user found');
    const { user } = this.props;
    const idToken = user.id_token;
    if (user !== prevProps.user) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setGlobalUserProps();
      api.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${idToken}`;
          return config;
        },
        (error) => Promise.reject(error)
      );
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            store.dispatch(userSignedOut());
            (window.location.pathname = '/Welcome');
          }
          return Promise.reject(error);
        }
      );
      api.get('/api/userSetting/GetUserSetting').then(({ data }) => {
        this.setState({ settings: data });
      });

      const smashid = cookieService.get('smashId');
      console.log(smashid);
      if (smashid) {
        this.setState({ smashId: smashid });
        api.put(`/api/smashes/${smashid}/associate`);
      }
    }
  }

  getStepText() { return 'DONE'; }

  notify(str) {
    copyToClipBoard(str, button);
  }

  formatString(str) {
    return str.replace(/\s+/g, '');
  }

  render() {
    const { history } = this.props;
    const { smashId, settings } = this.state;
    return (
      <div className={styles.block}>
        <div className={styles.header}>
          <h6 className={styles.h6}>
            <span className={styles.icon}>
              <button type="button" className={styles.backBtn} onClick={() => console.log('toggle back logic')}>
                <i className={cx(styles.backIcon, 'fa fa-angle-left')} aria-hidden="true"></i>
              </button>
              Share Smash
            </span>
            <span className={styles.breadcrumb}>5/5</span>
          </h6>
        </div>
        <div className={styles.body}>
          <>
            <div className={styles.done}>
              <img src={Done} alt="img" className={styles.responsive}></img>
            </div>
            <div className={styles.copySection}>
              <div>
                <i className={cx('material-icons generated-link-linkicon', styles.shareIcon)}>link</i>
                <input
                  className={styles.shareInput}
                  type="text"
                  placeholder="Generated link"
                  disabled=""
                  defaultValue={this.formatString(`${window.location.origin}/SmashExecution/${smashId}
                  ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`)}
                />
              </div>
              <div>
                <button
                  className={cx(styles.cpyBtn, 'waves-effect waves-light btn')}
                  type="button"
                  onClick={() => this.notify(this.formatString(`${window.location.origin}/SmashExecution/${smashId}
                  ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`))}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p className={styles.doneMessage}>
              You have created a Smash! But you won't learn much if nobody participates.
              Get this link in front of people you want to participate by including it in an email,
              linking it from paid ads, or posting it to your social media following. The more participants you get,
              the more confidence you can have in your results. So get sharing!
            </p>
          </>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={cx(styles.next, 'waves-effect waves-light btn-large')}
              onClick={() => history.push('/SmashListing')}
            >
              {this.getStepText()}
            </button>
            <button
              type="button"
              className={cx(styles.nextIcon, 'waves-effect waves-light btn-large')}
              onClick={() => history.push('/SmashListing')}
            >
              <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ShareSmash.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  setGlobalUserProps: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShareSmash);
