import { SET_CUSTOM_THEME } from '../actions/types';
import { GET_CUSTOM_THEME } from '../actions/types';

const initialState = {
  background: '',
  cardColor: '',
  smashText: '',
  cardText: '',
  button: '',
  buttonSplit: '',
  buttonText: ''
};

const customThemeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOM_THEME: return {
      ...state,
      ...payload
    };

    case GET_CUSTOM_THEME: return {
      ...state
    };

    default: return state;
  }
};

export default customThemeReducer;
