const local = {
  AppUri: {
    APP_AUTH_SERVICE_BASE: 'https://localhost:44366',
    APP_AUTH_REDIRECT_URI: 'https://localhost:3000/ShareSmash',
    APP_AUTH_LOGOUT_REDIRECT_URI: 'https://localhost:3000/Welcome',
    APP_RANKSMASH_API_BASE_URI: 'https://localhost:5000',
    APP_WELCOME_AUTH_REDIRECT_URI: 'https://localhost:3000/Welcome',
    DOMAIN: 'localhost'
  }
};
const dev = {
  AppUri: {
    APP_AUTH_SERVICE_BASE: 'https://ranksmash-auth-dev.azurewebsites.net',
    APP_AUTH_REDIRECT_URI: 'https://ranksmash-webs-dev.azurewebsites.net/ShareSmash',
    APP_AUTH_LOGOUT_REDIRECT_URI: 'https://ranksmash-webs-dev.azurewebsites.net/Welcome',
    APP_RANKSMASH_API_BASE_URI: 'https://ranksmash-service-dev.azurewebsites.net',
    APP_WELCOME_AUTH_REDIRECT_URI: 'https://ranksmash-webs-dev.azurewebsites.net/Welcome',
    DOMAIN: 'ranksmash-webs-dev.azurewebsites.net'
  }
};
const uat = {
  AppUri: {
    APP_AUTH_SERVICE_BASE: 'https://ranksmash-auth-uat.azurewebsites.net',
    APP_AUTH_REDIRECT_URI: 'https://rs-qa.ranksmash.com/ShareSmash',
    APP_AUTH_LOGOUT_REDIRECT_URI: 'https://rs-qa.ranksmash.com/Welcome',
    APP_RANKSMASH_API_BASE_URI: 'https://ranksmash-service-uat.azurewebsites.net',
    APP_WELCOME_AUTH_REDIRECT_URI: 'https://rs-qa.ranksmash.com/Welcome',
    DOMAIN: 'rs-qa.ranksmash.com'
  }
};
const prod = {
  AppUri: {
    APP_AUTH_SERVICE_BASE: 'https://login.ranksmash.com',
    APP_AUTH_REDIRECT_URI: 'https://app.ranksmash.com/ShareSmash',
    APP_AUTH_LOGOUT_REDIRECT_URI: 'https://app.ranksmash.com/Welcome',
    APP_RANKSMASH_API_BASE_URI: 'https://ranksmash-service.azurewebsites.net',
    APP_WELCOME_AUTH_REDIRECT_URI: 'https://app.ranksmash.com/Welcome',
    DOMAIN: 'app.ranksmash.com'
  }
};
console.log(process.env.REACT_APP_ENV);
const runtimeEnv = process.env.REACT_APP_ENV === 'dev' ? dev : process.env.REACT_APP_ENV === 'uat' ? uat
  : process.env.REACT_APP_ENV === 'prod' ? prod : local;
console.log(runtimeEnv);
export default runtimeEnv.AppUri;
