import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from '../createSmash/Create.module.css';

function CustomSmashThemeFooter(props) {
  const { handleSubmit, disabled } = props;
  return (
    <div className={styles.footer}>
      <div className={styles.buttonGroup}>
        <button
          type="button"
          disabled={disabled}
          className={cx(styles.next, 'waves-effect waves-light btn-large')}
          onClick={handleSubmit}
        >
          SAVE GLOBAL THEME
        </button>
        <button
          type="button"
          disabled={disabled}
          className={cx(
            styles.nextIcon,
            'waves-effect waves-light btn-large'
          )}
          onClick={handleSubmit}
        >
          <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
}

CustomSmashThemeFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CustomSmashThemeFooter;
