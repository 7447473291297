/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import Api from 'api';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { take, sortBy } from 'lodash';

class ParticipantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantInsight: [],
      showMore: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location: { state: { data } } } = this.props;
    // Bug 33626: User is not able to view result in participant screen for +1 email ids
    //  Bug 34181: All duplicate sessions is contain the Top 5 Ranked item value
    //  of first session on Group Session list screen
    Api.getSessionAnalysis(data.smashId, data?.sessionId)
      .then(res => {
        this.setState({ participantInsight: res.data, isLoading: false });
      });
  }

  render() {
    const { participantInsight, showMore, isLoading } = this.state;
    const { history } = this.props;
    return (
    // <!-- wrapper starts -->
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* <!-- smash-block starts --> */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh6">
                  <button type="button" className="backBtn" onClick={() => history.goBack()}>
                    <i className="backIcon fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <span className="header-icon">
                    {participantInsight?.participant?.participantEmail}
                  </span>
                </h6>
              </div>
              <div className="smash-body smash-body-participant">
                {isLoading ? (
                  <div className="backdroploader">
                    <div className="loader"></div>
                  </div>
                ) : '' }
                <div className="participant-detail-div">
                  <div className="participant-list-img participant-list-img-participant">
                    <i className="fa fa-user-o" aria-hidden="true"></i>
                  </div>
                  <div className="participant-list-detail">
                    <h4>{participantInsight?.participant?.participantEmail}</h4>
                  </div>
                </div>
                <div className="smash-detail-div smash-detail-div-participant">
                  <div className="smash-detail-items smash-detail-items-participant">
                    <div className="ranked-items ranked-items-participant">
                      <h4>
                        {participantInsight?.participant?.totalSmashDuration}
                        {'sec'}
                      </h4>
                      <p>Time spent on the smash</p>
                    </div>
                    <div className="ranked-items non-ranked-items ranked-items-participant">
                      <h4>
                        {participantInsight?.participant?.avgDecisionDuration}
                        {'sec'}
                      </h4>
                      <p>Time spent per comparison</p>
                    </div>
                  </div>
                </div>
                <div className="smash-lists-block smash-detail-block">
                  {/* User Story 26900: Top results says "top 5 results" even if there are only 4 items */}
                  <h5>
                    {participantInsight?.rankedSmashItems?.length < 5
                      ? `Top ${participantInsight?.rankedSmashItems?.length} Ranked Items`
                      : 'Top 5 Ranked Items'}
                  </h5>
                  <span
                    role="presentation"
                    className="participants-view-all-span"
                    onClick={() => {
                      this.setState({ showMore: !showMore });
                    }}
                  >
                    {!showMore ? 'View All Items' : 'View Less Items' }
                  </span>
                </div>
                <div className="smash-items-list">
                  {take(sortBy(participantInsight?.rankedSmashItems, o => o.rank),
                    showMore ? participantInsight?.rankedSmashItems?.length : 5)?.map(
                    item => (
                      <div className="smash-items-block">
                        <p className="smash-items-block-item-name">
                          {item?.item?.imageURL ? (
                            <img className="img" width="36px" height="36px" src={item.item.imageURL} alt="" />
                          ) : (
                            <i className="material-icons">image</i>
                          )}
                          {item?.item?.imageTag}
                        </p>
                        <span className="smash-items-block-item-number">
                          #
                          {item.rank}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {/* <!-- smash-block ends --> */}
          </div>
        </div>
      </div>
    // <!-- wrapper ends -->

    );
  }
}

ParticipantDetail.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ParticipantDetail);
