/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const getUserTracking = () => connector.get('/api/userSetting/GetUserSetting');
const createUserTracking = (payload) => connector.post('/api/userSetting', payload);
const updateUserTracking = (payload) => connector.put('/api/userSetting', payload);
const deleteSmashLogo = () => connector.delete('/api/userSetting/smashLogo');

const createCustomTheme = (payload) => connector.post('/api/userSetting/CreateGlobalCustomSmashTheme', payload);
const updateCustomTheme = (payload) => connector.put('/api/userSetting/UpdateGlobalCustomSmashTheme', payload);
// const endPointCreateCapturedUserInformation = '/api/userSetting/CreateCapturedUserInformation';
// const createCapturedUserInformation = (payload) => connector.post(endPointCreateCapturedUserInformation, payload);

const usersettingApis = {
  createUserTracking,
  getUserTracking,
  updateUserTracking,
  deleteSmashLogo,
  createCustomTheme,
  updateCustomTheme,
  // createCapturedUserInformation
};

export default usersettingApis;
