/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './Timer.module.css';

function Timer({
  secs = 0, onTimeout, showTimer, customTheme
}) {
  const [count, setCount] = useState(secs);

  const timerFunc = setTimeout(() => {
    const updated = count - 1;
    setCount(updated);
  }, 1000);

  useEffect(() => {
    if (count < 0) {
      onTimeout();
      clearTimeout(timerFunc);
    }
  }, [count]);

  if (!showTimer) {
    return (
      <div className={styles.text}>
      </div>
    );
  }

  const text = moment.utc(count * 1000).format('mm:ss');
  return (
    <div className={styles.text} style={{ color: customTheme?.smashText }}>
      {text}
    </div>
  );
}

Timer.propTypes = {
  secs: PropTypes.number.isRequired,
  onTimeout: PropTypes.func.isRequired,
  showTimer: PropTypes.bool.isRequired,
  customTheme: PropTypes.object.isRequired,
};

export default Timer;
