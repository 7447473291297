/* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Api from 'api';
import cx from 'classnames';
import { getTicks } from './utils/Utils';
import styles from './SmashPairs.module.css';

function SmashPairs({
  list,
  callback,
  setSelectedPairs,
  isPreview,
  previewCustomTheme,
  startMessage,
  customTheme
}) {
  const boxRef = useRef();
  const [initialTick, setInitialTick] = useState();

  // Bug 33444: Presented smash pair count is coming less than actual presented count pair
  const updateOption = async (i, item, selected, isLast) => {
    if (!isPreview) {
      if (!previewCustomTheme) {
        const { smashId, sessionId } = item;
        const winTime = (getTicks() - initialTick).toString();
        const payload = {
          ...item,
          winnerItem: item[selected],
          winnerDecisionTime: winTime,
          item1DisplayOrder: 1,
          item2DisplayOrder: 2,
          // User Story 32664: Distinguish between Smash pair created by user vs system - Database
          generatedFrom: 0
        };
        await Api.updateSmashPair(smashId, sessionId, payload);
      }
    }

    setInitialTick(getTicks());
    if (isLast) {
      // Bug 33444: Presented smash pair count is coming less than actual presented count pair
      callback(i);
    }
  };

  const toggleNext = (i, item, selected) => {
    if (!boxRef || !boxRef.current) {
      return;
    }
    setSelectedPairs(i);
    const len = list.length;
    if (i !== len) {
      const { width } = boxRef.current.getBoundingClientRect();
      boxRef.current.style.transform = `translateX(-${i * width}px)`;
      // Bug 33444: Presented smash pair count is coming less than actual presented count pair
      updateOption(i, item, selected, false);
    } else {
      updateOption(i, item, selected, true);
    }
  };

  const longestWordLength = (text) => {
    const words = text.split(' ');
    let maxLength = 0;
    for (let i = 0; i < words.length; i++) {
      if (words[i].length > maxLength) {
        maxLength = words[i].length;
      }
    }
    return maxLength;
  };

  const calibrateFont = (text, imageUrl) => {
    let styleclass;
    const textlength = text.length;
    if (imageUrl !== null) {
      if (textlength <= 20) {
        styleclass = `${styles.descBig}`;
      } else if ((textlength > 20 && textlength <= 60)) {
        styleclass = `${styles.descMedium}`;
      } else if ((textlength > 60)) {
        styleclass = `${styles.descSmall}`;
      }
    } else if (textlength <= 20) {
      styleclass = `${styles.descBigWoImage}`;
    } else if (textlength > 20 && textlength <= 60) {
      styleclass = `${styles.descMediumWoImage}`;
    } else if ((textlength > 60)) {
      styleclass = `${styles.descSmallWoImage}`;
    }
    const maxWordLength = longestWordLength(text);
    if (maxWordLength > 24 && textlength > 60) {
      styleclass = `${styles.descFontBigWord}`;
    } else if (maxWordLength > 24 && textlength <= 60) {
      styleclass = `${styles.descMedium}`;
    }
    return styleclass;
  };
  const calibrateHeaderFont = (textlength) => {
    let styleclass;
    if (textlength < 80) {
      styleclass = `${styles.tqTextLarge}`;
    } else if (textlength > 80 && textlength < 100) {
      styleclass = `${styles.tqTextMedium}`;
    } else if (textlength > 100) {
      styleclass = `${styles.tqTextSmall}`;
    }
    return styleclass;
  };

  const calibrateTextBlock = (textlength, imageUrl) => {
    let styleclass = `${styles.textBlock}`;

    if (imageUrl !== null) {
      // Bug 26901: Items are not being sized the same during smash display
      if ((textlength > 90 && textlength < 120)) {
        styleclass = `${styles.textBlock}`;
      } else if ((textlength >= 120)) {
        styleclass = `${styles.textBlock}`;
      }
    }
    return styleclass;
  };

  useEffect(() => {
    setInitialTick(getTicks());
  }, []);

  return (
    <div className={styles.thankyouBlock}>
      <div className={styles.smashDesc}>
        <h3
          ref={el => {
            if (el && customTheme?.smashText) {
              el.style.setProperty('--underlineColor', customTheme?.smashText);
            }
          }}
          style={{ color: customTheme?.smashText }}
          className={calibrateHeaderFont(startMessage?.length || 0)}
        >
          {startMessage}
        </h3>
        <h5 className={styles.emailText} style={{ color: customTheme?.smashText }}>
          Select One
        </h5>
      </div>

      <div className={styles.selectionBox} ref={boxRef}>
        {list && list.map((item, i) => (
          <div key={i} className={styles.itemContainer}>
            <div
              role="presentation"
              className={styles.card}
              style={{ background: customTheme?.cardColor }}
              onClick={() => toggleNext(i + 1, item, 'item1')}>
              <div className={styles.imgAndPContainer}>
                {item.item1.imageURL && (
                  <div className={styles.imgBlock}>
                    {/* User Story 31089: Maintain aspect ratio of Square images  */}
                    <img
                      src={item.item1.imageURL}
                      alt="img"
                      className={(!item?.item1.smashItemText && !item?.item2.smashItemText)
                        || (!item?.item1.smashItemText && item?.item2.smashItemText)
                        ? styles.bothImg : item?.item1.smashItemText ? styles.img : styles.ImgText} />
                  </div>
                )}
                {item.item1.smashItemText && (
                  <div className={calibrateTextBlock(item.item1.smashItemText.length, item.item1.imageURL)}>
                    <p
                      style={{ color: customTheme?.cardText }}
                      className={calibrateFont(item.item1.smashItemText, item.item1.imageURL)}>
                      {item.item1.smashItemText}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.buttonGroupWithinCards}>
                <button
                  ref={el => {
                    if (el) {
                      el.style.setProperty('background-color', customTheme?.button, 'important');
                    }
                  }}
                  style={{ color: customTheme?.buttonText }}
                  type="button"
                  className={cx(styles.thisAndThatButton, 'waves-effect waves-light btn-small')}
                >
                  This

                </button>
                <button
                  ref={el => {
                    if (el) {
                      el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                    }
                  }}
                  style={{ color: customTheme?.buttonText }}
                  type="button"
                  className={cx(styles.thisAndThatButtonIcon, 'waves-effect waves-light btn-small')}>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <h4
              className={styles.or}
              style={{ color: customTheme?.smashText }}
            >
              OR
            </h4>
            <div
              role="presentation"
              className={styles.card}
              style={{ background: customTheme?.cardColor }}
              onClick={() => toggleNext(i + 1, item, 'item2')}>
              <div className={styles.imgAndPContainer}>
                {item.item2.smashItemText && (
                  <div
                    className={calibrateTextBlock(item.item2.smashItemText.length, item.item2.imageURL)}>
                    <p
                      style={{ color: customTheme?.cardText }}
                      className={calibrateFont(item.item2.smashItemText, item.item2.imageURL)}
                    >
                      {item.item2.smashItemText}
                    </p>
                  </div>
                )}
                {item.item2.imageURL && (
                  <div className={styles.imgBlock}>
                    {/* User Story 31089: Maintain aspect ratio of Square images  */}
                    <img
                      src={item.item2.imageURL}
                      alt="img"
                      className={(!item?.item1.smashItemText && !item?.item2.smashItemText)
                        || (item?.item1.smashItemText && !item?.item2.smashItemText)
                        ? styles.bothImg : item?.item2.smashItemText ? styles.img : styles.imgText} />
                  </div>
                )}
              </div>
              <div className={styles.buttonGroupWithinCards}>
                <button
                  ref={el => {
                    if (el) {
                      el.style.setProperty('background-color', customTheme?.button, 'important');
                    }
                  }}
                  style={{ background: customTheme?.button, color: customTheme?.buttonText }}
                  type="button"
                  className={cx(styles.thisAndThatButton, 'waves-effect waves-light btn-small')}
                >
                  That
                </button>
                <button
                  ref={el => {
                    if (el) {
                      el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                    }
                  }}
                  style={{ color: customTheme?.buttonText }}
                  type="button"
                  className={cx(styles.thisAndThatButtonIcon, 'waves-effect waves-light btn-small')}>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
SmashPairs.defaultProps = {
  previewCustomTheme: false,
};

SmashPairs.propTypes = {
  list: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  setSelectedPairs: PropTypes.func.isRequired,
  isPreview: PropTypes.bool.isRequired,
  previewCustomTheme: PropTypes.bool,
  startMessage: PropTypes.string.isRequired,
  customTheme: PropTypes.object.isRequired
};

export default SmashPairs;
