import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import Api from 'api';
// import * as rdd from 'react-device-detect';
import { themeSavedToast } from '../../utils/Toast';
import CustomSmashThemeFooter from './CustomSmashThemeFooter';
import CustomSmashThemeHeader from './CustomSmashThemeHeader';
import CustomSmashThemeBody from './CustomSmashThemeBody';

function CustomSmashTheme() {
  const [formValues, setFormValues] = useState({
    background: '',
    cardColor: '',
    smashText: '',
    cardText: '',
    button: '',
    buttonSplit: '',
    buttonText: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { smashId, step } = useParams();
  const history = useHistory();
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const formRef = useRef();

  // const success = pos => console.log('getCurrentPosition', pos);
  // const error = error => console.log('err', error);

  useEffect(() => {
    // console.log('rdd', rdd);
    // console.log('mediaDevices', window.navigator.mediaDevices);
    // window.navigator.geolocation.getCurrentPosition(success, error);
    // console.log('connection', window.navigator.connection);
    // console.log('userAgent', window.navigator.userAgent);
    Api.getUserTracking()
      .then((apiData) => {
        if (apiData.data.smashTheme) {
          setFormValues(apiData.data.smashTheme);
        } else {
          setIsPost(true);
        }
      });
  }, []);

  useEffect(() => {
    const empty = Object.values(formValues).some(e => e !== '');
    if (empty) {
      setDisableSubmit(false);
    }
  }, [formValues]);

  const themeSavedToastButton = (
    <div className="flex">
      <span className="width80">Global Theme Saved</span>
      <button
        type="button"
        className="closeButton"
      >
        <span><i className={cx('closeicon', 'material-icons')}>clear</i></span>
      </button>
    </div>
  );

  const navigateAfterSavingTheme = () => {
    if (smashId && step) {
      history.push(`/CreateSmashConfiguration/${smashId}/${step}`);
    } else history.goBack();
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isPost) {
        Api.createCustomTheme(formValues)
          .then((apiData) => {
            setFormValues(apiData.data);
            themeSavedToast('Global Theme Saved', themeSavedToastButton);
            navigateAfterSavingTheme();
          })
          .catch(err => console.log(err));
      } else {
        Api.updateCustomTheme(formValues)
          .then((apiData) => {
            setFormValues(apiData.data);
            themeSavedToast('Global Theme Saved', themeSavedToastButton);
            navigateAfterSavingTheme();
          })
          .catch(err => console.log(err));
      }
    }
  }, [formErrors]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  };

  const previewSmash = () => {
    Api.updateCustomTheme(formValues)
      .then((apiData) => {
        setFormValues(apiData.data);
        history.push('/CustomSmashTheme/preview');
      })
      .catch(err => console.log(err));
  };

  function validate(values) {
    const errors = {};
    const {
      background, smashText, cardColor, cardText, button, buttonText, buttonSplit
    } = values;

    if (background.trim() !== '' && smashText.trim() !== '') {
      if (background === smashText) {
        errors.background = 'Background and Smash Text can not be the same. Text will be invisible.';
        errors.smashText = 'Background and Smash Text can not be the same. Text will be invisible.';
      }
    }
    if (cardColor.trim() !== '' && cardText.trim() !== '') {
      if (cardColor === cardText) {
        errors.cardColor = 'Card Color and Card Text can not be the same. Text will be invisible.';
        errors.cardText = 'Card Color and Card Text can not be the same. Text will be invisible.';
      }
    }
    if (button.trim() !== '' && buttonText.trim() !== '') {
      if (button === buttonText) {
        errors.button = 'Button Color and Button Text can not be the same. Text will be invisible';
        errors.buttonText = 'Button Color and Button Text can not be the same. Text will be invisible';
      }
    }
    if (buttonSplit.trim() !== '' && buttonText.trim() !== '') {
      if (buttonSplit === buttonText) {
        errors.buttonSplit = 'Button (Split) and Button Text can not be the same. Text will be invisible.';
        errors.buttonText = 'Button (Split) and Button Text can not be the same. Text will be invisible.';
      }
    }

    Object.entries(values).forEach((key) => {
      if (key[1].trim() !== '') {
        if (!regex.test(key[1])) {
          errors[key[0]] = 'Please enter a valid hexcode';
        }
      }
    });

    return errors;
  }

  const handleSubmit = () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  return (
    <div className="smash-block">
      <CustomSmashThemeHeader
        smashId={smashId}
        step={step}
        navigateAfterSavingTheme={navigateAfterSavingTheme}
      />
      <CustomSmashThemeBody
        formValues={formValues}
        formErrors={formErrors}
        ref={formRef}
        handleChange={handleChange}
        previewSmash={previewSmash}
      />
      <CustomSmashThemeFooter
        handleSubmit={handleSubmit}
        disabled={disableSubmit}
      />
    </div>
  );
}

export default CustomSmashTheme;
