/* eslint-disable linebreak-style */
export const getInfoText = (type) => {
  let text = '';
  switch (type) {
    case 'description':
      text = 'Which option do you like better?';
      break;
    case 'customizeSmashIntroText':
      text = 'Customize the instructions you give your participants when they come to your Smash Intro Screen.';
      break;
    case 'name':
      text = 'This name is visible to participants, please name it accordingly.';
      break;
    case 'items':
      text = 'This is where you are able to add items for your prospects or existing users to compare.'
        + ' You can display images only, text only, or both combined. Make sure to preview your Smash to'
        + ' ensure that you are satisfied with the look and feel. If not, make any needed adjustments.';
      break;
    case 'imageTag':
      text = 'This name is for your reference. It is used on reports, but is invisible to participants.';
      break;
    case 'imageDesc':
      text = 'This is the text that the participant will see when making their decision. You can have text alone, '
        + 'or you can have this text  and an image if you add one to this item.';
      break;
    case 'addImage':
      text = 'If you are displaying an image alone, please make sure your image dimension (W x H) is in '
        + 'range of 150 by 150 to 700 by 300 and no larger than 10MB. '
        + 'If your image will be displayed next to text, please ensure '
        + 'that it is a square of 150 by 150 to 300 by 300.';
      break;
    case 'redirect':
      text = 'This url will redirect a participant to the specified url when clicked.'
        + '(ie- sales page, cart, learn more, etc)';
      break;
    case 'timer':
      text = 'Enable this option if you want your participants to see a timer counting down.'
        + 'When the timer reaches 0:00 the smash will automatically end for that user.';
      break;
    case 'timerDuration':
      text = 'This is the amount of time that you will let the Smash run before automatically closing. '
        + 'If you would NOT like to have a set time, please set the time to 0 mins.';
      break;
    case 'completion':
      text = 'This is the message that your participants will see upon completion.';
      break;
    case 'results':
      text = 'This option will show the prospect or user how their results compare to everyone who has gone '
        + 'through your experience. '
        + 'If it is in the off position, they will just see the results of their own comparisons.';
      break;
    case 'CollectContactData':
      text = 'If you want to collect the contact information from someone who completes a smash; turn this on.';
      break;
    case 'emailAddress':
      text = 'This asks for the email address of the participant at the end of the smash.';
      text += 'If you already know their email,';
      text += 'you can pass it as a URL parameter with the tag `em=john@doe.com` for example';
      break;
    case 'firstName':
      text = 'This asks for the first name of the participant at the end of the smash.';
      text += 'If you already know it,';
      text += 'you can pass it as a URL parameter with the tag `fn=john` for example';
      break;
    case 'lastName':
      text = 'Request the last name of the participant at the end of the smash.';
      text += 'If you already know it, ';
      text += ' you can pass it as a URL parameter with the tag `ln=doe` for example';
      break;
    case 'phoneNumber':
      text = 'Ask the participant for their phone number at the end of the smash. ';
      text += 'If you already know it, ';
      text += 'you can pass it as a URL parameter with the tag `ph=14805556478` for example';
      break;
    case 'webhook':
      text = 'This webhook will fire for each participant who completes your smash. ';
      text += 'Note: if no email is present, your session data will still be sent to your webhook.';
      break;
    case 'globalRedirectURL':
      text = 'Paste ANY URL here. Your DESKTOP participants will be redirected with there top ITEM NAME added as a ';
      text += 'parameter to the end of your URL for tracking and personalization. MOBILE participants will be ';
      text += 'redirected when they click on specific items IF you have added a redirect to the individual item.';
      break;
    case 'hideSmashIntro':
      text = 'Toggling this feature ON will remove the RankSmash start screen.';
      text += 'Please provide instructions to your audience as they will start your Smash without an introduction. ';
      break;
    case 'applyCustomTheme':
      text = 'You have the ability to customize the look and feel of your Smashes at a global level.';
      text += 'If you’d like to edit your custom theme, click the Edit Icon. Note: changes are globally applied.';
      break;
    case 'background':
      text = 'Specify a specific color for your Smash background. If you leave it blank we will ';
      text += 'display the default gradient.';
      break;
    case 'cardColor':
      text = 'Most of the time people leave this as #FFFFFF, but you can choose a different ';
      text += 'card color if you\'d like.';
      break;
    case 'smashText':
      text = 'How would you like the text on the Smash to appear? For best results, the ';
      text += 'Smash Text and Background Color should not be the same.';
      break;
    case 'cardText':
      text = 'What color would you like to make the Card Text? For best results, the ';
      text += 'Card Text and Card Color should not be the same.';
      break;
    case 'button':
      text = 'How would you like the button to look? This is a split button. ';
      text += 'This value will be the primary color of the button.';
      break;
    case 'buttonSplit':
      text = 'How would you like the split button to look? This is a smaller section ';
      text += 'on the right of the button. This value will be the secondary color of the button. ';
      text += 'Note: your Button and Button (Split) values can be the same if you\'d like.';
      break;
    case 'buttonText':
      text = 'What color should we make the button text? Totally up to you. ';
      text += 'For best results, the Button Text color should be different than the Button or Button (split) colors.';
      break;
    default:
      text = '';
  }
  return text;
};
