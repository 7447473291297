/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const getGroupSessions = (smashId, email) => connector.get(
  `/api/SmashAnalysis/${smashId}/ParticipantSession/${email}`
);

const groupSession = {
  getGroupSessions
};

export default groupSession;
