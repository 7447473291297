import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Preview.module.css';
import PhoneNumber from '../shared/PhoneNumber';

function CollectContactdata({
  smash,
  customTheme,
  showContactData,
  isFormValid,
  handleNext,
  collectFirstNameRequired,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  isFirstNameValid,
  isLastNameValid,
  isEmailValid,
  collectLastNameRequired,
  phone,
  setPhone,
  email,
  setEmail,
  collectEmailRequired,
  collectPhoneRequired,
  isPhoneValid
}) {
  return (
    <div className={styles.thankyouBlock}>
      <h3
        className={styles.tqText}
        style={{ color: customTheme?.smashText }}
        ref={el => {
          if (el && customTheme?.smashText) {
            el.style.setProperty('--underlineColor', customTheme?.smashText);
          }
        }}
      >
        {smash?.smashEndMessage ? smash?.smashEndMessage : 'Preview Completed'}
      </h3>
      {showContactData && (
        <div className="preview-thankyou-form-fiv">
          <h5
            className={styles.emailText}
            style={{ color: customTheme?.smashText }}
          >
            Enter your contact data to see the results of your smash
          </h5>
          <form
            className="preview-thankyou-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (!isFormValid()) {
                handleNext();
              }
            }}
          >
            <div className="preview-thankyou-block">
              <div className="input-field input-smash">
                <div className={cx(styles.block, 'input-field')}>
                  {collectFirstNameRequired && (
                    <>
                      <input
                        className={styles.field}
                        autoFocus="autofocus"
                        type="text"
                        placeholder="First Name"
                        id="name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        onBlur={(e) => {
                          if (isFirstNameValid) {
                            e.target.classList.remove('error');
                          } else {
                            e.target.classList.add('error');
                          }
                        }}
                      />
                    </>
                  )}
                  {collectLastNameRequired && (
                    <>
                      <input
                        className={styles.field}
                        type="text"
                        placeholder="Last Name"
                        id="name"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        onBlur={(e) => {
                          if (isLastNameValid) {
                            e.target.classList.remove('error');
                          } else {
                            e.target.classList.add('error');
                          }
                        }}
                      />
                    </>
                  )}
                  {collectPhoneRequired && (
                    <>
                      <PhoneNumber
                        className={styles.field}
                        type="number"
                        id="phone"
                        onChange={(value) => setPhone(value)}
                        value={phone}
                        onBlur={(e) => {
                          if (isPhoneValid) {
                            e.target.classList.remove('error');
                          } else {
                            e.target.classList.add('error');
                          }
                        }}
                      />
                    </>
                  )}
                  {collectEmailRequired && (
                    <>
                      <input
                        className={styles.field}
                        type="email"
                        placeholder="Yourbest@email.com"
                        id="name"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        onBlur={(e) => {
                          if (isEmailValid) {
                            e.target.classList.remove('error');
                          } else {
                            e.target.classList.add('error');
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

CollectContactdata.propTypes = {
  smash: PropTypes.object.isRequired,
  customTheme: PropTypes.object.isRequired,
  showContactData: PropTypes.bool.isRequired,
  isFormValid: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  collectFirstNameRequired: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  isFirstNameValid: PropTypes.bool.isRequired,
  isLastNameValid: PropTypes.bool.isRequired,
  isEmailValid: PropTypes.bool.isRequired,
  collectLastNameRequired: PropTypes.bool.isRequired,
  collectPhoneRequired: PropTypes.bool.isRequired,
  collectEmailRequired: PropTypes.bool.isRequired,
  isPhoneValid: PropTypes.bool.isRequired,

};
export default CollectContactdata;
