import { api as connector } from './axios';

const getGroupSessionAnalysis = (smashId, email) => connector.get(
  `/API/SmashAnalysis/${smashId}/ParticipantSmashInsight/${email}`
);

const groupSessionAnalysis = {
  getGroupSessionAnalysis,
};

export default groupSessionAnalysis;
