/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const associatesmash = (smashId) => connector.put(`/api/smashes/${smashId}/associate`);

const associateSmashes = {
  associatesmash
};

export default associateSmashes;
