/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './ParticipantsList.css';
import Api from 'api';

class GroupSessionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantSession: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location: { state: { data } } } = this.props;
    // Bug 34153: Group session List page is not loading when user entered +1 email id
    const encodedString = Buffer.from(data.participantEmail).toString('base64');
    if (encodedString) {
      Api.getGroupSessions(data.smashId, encodedString)
        .then(res => {
          this.setState({ participantSession: res.data, isLoading: false });
        });
    }
  }

  render() {
    const { participantSession, isLoading } = this.state;
    const { location: { state: { data } } } = this.props;
    const { history } = this.props;
    return (
    // <!-- wrapper starts -->
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* <!-- smash-block starts --> */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh6">
                  <button type="button" className="backBtn" onClick={() => history.goBack()}>
                    <i className="backIcon fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <span className="header-icon">
                    {data.participantEmail}
                  </span>
                </h6>
              </div>
              <div className="smash-body">
                {isLoading ? (
                  <div className="backdroploader">
                    <div className="loader"></div>
                  </div>
                ) : '' }
                <div className="smash-lists-block smash-detail-block">
                  <h5>Group Sessions</h5>
                </div>
                {
                  participantSession.map(participant => (
                    <div className="smash-participants-lists-div" key={participant.smashid}>
                      <Link
                        to={{ pathname: '/ParticipantDetail', state: { data: participant } }}>
                        <div className="participants-list-block">
                          <div className="participant-list-img">
                            <i className="fa fa-user-o" aria-hidden="true"></i>
                          </div>
                          <div className="participant-list-det">
                            <p>{moment(participant.participationDate).format('DD-MMM-YYYY')}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
            {/* <!-- smash-block ends --> */}
          </div>
        </div>
      </div>
    // <!-- wrapper ends -->
    );
  }
}

GroupSessionList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(GroupSessionList);
