/* eslint-disable linebreak-style */
export const getTicks = () => {
  // const TICK_CONSTANT = 621355968000000000;
  const date = new Date();
  const ticks = ((date.getTime() * 10000));
  return ticks;
};

export const getStepText = (step) => {
  if (step === 0) {
    return 'Start Smash';
  }
  if (step === 3) {
    return 'Done';
  }
  if (step === 2) {
    return 'Proceed';
  }
  return '';
};

export const getSessionPayload = (
  smash,
  sessionStart,
  sessionEnd,
  presentedSmashPairCount = 0,
  totalSmashPairCount = 0,
  rankSmashTenantId = null,
  participant = null
) => {
  const { sessionId, smashId } = smash;
  return {
    sessionId,
    smashId,
    sessionStart,
    sessionEnd,
    clientDevice: null,
    clientIPAddress: null,
    clientAgent: null,
    rankSmashTenantId,
    presentedSmashPairCount,
    totalSmashPairCount,
    participant
  };
};
