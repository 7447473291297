/* eslint-disable linebreak-style */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './IosSwitch.module.css';

function IosSwitch({
  name, checked, onChange, onClick, disable = false
}) {
  return (
    <div className={cx('switch')}>
      <label className={styles.label} onClick={(e) => onClick(e)} aria-hidden="true">
        <input
          checked={checked}
          onChange={(e) => onChange(e)}
          className={styles.input}
          type="checkbox"
          name={name}
          disabled={disable} />
        <span className={cx(styles.lever, 'lever')}></span>
      </label>
    </div>
  );
}

IosSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disable: PropTypes.bool
};

IosSwitch.defaultProps = {
  disable: false
};
export default IosSwitch;
