/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const getParticipants = (payload) => connector.get(`/API/SmashAnalysis/${payload}/Participants`, payload);

const participants = {
  getParticipants
};

export default participants;
