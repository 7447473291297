/* eslint-disable linebreak-style */
import React, { useState, useEffect, useRef } from 'react';
// import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './ToolTip.module.css';

function ToolTip({
  text, label, icon, additionalIcon
}) {
  const [info, showInfo] = useState(false);
  const btnRef = useRef();

  useEffect(() => {
    if (info) {
      btnRef.current.focus();
    }
  }, [info]);

  return (
    <div className={styles.text}>
      <div className={styles.labelAndTooltipContainer}>
        <label className={styles.label}>
          {label}
        </label>
        <span
          role="presentation"
          className={cx(styles.infoIcon)}
          onKeyPress={() => false}
          onClick={() => showInfo(!info)}
        >
          <i className={cx('material-icons')}>{icon}</i>
          {info && <span className={styles.pointer}></span>}
        </span>
        <div className={cx(styles.info, info ? styles.show : '')}>
          <p>
            {text}
          </p>
          <p>
            <span
              role="button"
              tabIndex="0"
              autoFocus
              onBlur={() => {
                showInfo(!info);
              }}
              ref={btnRef}
              onKeyPress={() => false}
              className={cx(styles.tooltip)}
            >
            </span>
          </p>
        </div>
      </div>
      {
        additionalIcon && (
          <i
            style={{ float: 'right' }}
            className={cx(styles.tooltip, 'material-icons')}
          >
            {additionalIcon}
          </i>
        )
      }
    </div>
  );
}

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  additionalIcon: PropTypes.node
};

ToolTip.defaultProps = {
  additionalIcon: null
};

export default ToolTip;
