/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Api from 'api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Timer from 'components/shared/Timer';
import { isEmpty } from 'lodash';
import reactGA from 'react-ga';
import reactfbp from 'react-facebook-pixel';
// import * as rdd from 'react-device-detect';
import Results from './Results';
import { getStepText, getSessionPayload } from './utils/Utils';
import SmashPairs from './SmashPairs';
import SmashPairsV2 from './SmashPairsV2';
import styles from './Preview.module.css';
import CollectContactdata from './CollectContactData';
import { defaultSmashPair } from '../../Constants/HardCodedSmash';
// import { getIPAddress } from '../../api/captureLoggedInUserData';

function Preview() {
  const startStr = 'When you click ‘Start Smash’ below, you\'ll pick one of two choices until you’ve run out of time, '
  + 'or have reviewed all the possible pairs of items. Pick the item that best answers the question you will '
  + 'see at the top of the screen.';
  const [smash, setSmash] = useState({});
  const [showContactData, setShowContactData] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const { smashId, preview } = useParams();
  const isLastStep = step === 2;
  const isFirstStep = step === 0;
  const [collectEmailRequired, setCollectEmailRequired] = useState(false);
  const [collectFirstNameRequired, setCollectFirstNameRequired] = useState(false);
  const [collectLastNameRequired, setCollectLastNameRequired] = useState(false);
  const [collectPhoneRequired, setCollectPhoneRequired] = useState(false);
  const [customTheme, setCustomTheme] = useState({});
  const [showTimer, setShowTimer] = useState(false);
  const [previewCustomTheme, setPreviewCustomTheme] = useState(false);
  const showSmashResults = smash?.showSmashResults;
  // Issue 30691: Display Timer should not show on smash when user select the Display timer ON and set value "0"
  // const showTimer = smash?.smashDuration === 0 ? false : smash?.displaySmashTimer;
  const smashDuration = smash?.smashDuration === 0 ? 10000000 : smash?.smashDuration;
  const [sessionStart, setSessionStart] = useState(null);
  const [sessionEnd, setSessionEnd] = useState(null);
  const [selectedPairs, setSelectedPairs] = useState(0);
  const [introText, setIntroText] = useState(startStr);
  const isPreviewMode = !isEmpty(preview);
  const isCreate = !isEmpty(preview);
  const history = useHistory();
  const queryStringVal = useLocation();
  const createRegExp = (str, opts) => new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '');
  const emailRegex = createRegExp`
  ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
  (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
  (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`;
  const nameRegex = /^[a-zA-Z]+$/;
  // const [ipAdd, setIpAdd] = useState('');
  // const [geoLocation, setGeoLocation] = useState({
  //   cords: {
  //     accuracy: 0,
  //     altitude: null,
  //     altitudeAccuracy: null,
  //     heading: null,
  //     latitude: 0,
  //     longitude: 0,
  //     speed: null
  //   },
  //   timestamp: new Date()
  // });
  // const [userinformation, setUserinformation] = useState({
  //   smashId: '',
  //   ipAddress: ipAdd,
  //   loggedInUserInformation: {
  //     userId: '',
  //     username: ''
  //   },
  //   connection: {
  //     downlink: 0,
  //     effectiveType: '',
  //     onchange: null,
  //     rtt: 0,
  //     saveData: false
  //   },
  //   geolocation: geoLocation,
  //   userAgent: {
  //     browserName: '',
  //     browserVersion: '',
  //     deviceType: '',
  //     engineName: '',
  //     engineVersion: '',
  //     fullBrowserVersion: '',
  //     osName: '',
  //     osVersion: '',
  //     mobileModel: '',
  //     mobileVendor: ''
  //   }
  // });

  // If HideSmashIntro is true than we are Initializing SmashSession
  const InitializeSmashSession = (smashData) => {
    const start = new Date().toISOString();
    setSessionStart(start);
    const contactDataValue = new URLSearchParams(
      queryStringVal.search
        .replace('?', '')
        .split('&')
        .reduce((params, current) => {
          const [key, value] = current.split('=');
          params[key] = value;
          return params;
        }, {})
    );

    // User Story 28071: Enable "em" OR "email" smash execution url params
    // Bug 34264: "+" sign from email is not displaying on all participant screen
    // when user share smash & entered "+" email
    const participantObject = {
      email: contactDataValue?.get('email') || contactDataValue?.get('em'),
      firstName: contactDataValue?.get('fn') || contactDataValue?.get('firstname'),
      lastName: contactDataValue?.get('ln') || contactDataValue?.get('lastname'),
      phone: contactDataValue?.get('ph') || contactDataValue?.get('phone')
    };
    if (participantObject?.email) {
      // Issue 41964: Emails are being captured as %40 (raised by Client)
      // setEmail(participantObject?.email);
      setEmail(decodeURIComponent(participantObject?.email));
      setCollectEmailRequired(false);
      // Issue 41964: Emails are being captured as %40 (raised by Client)
      // smash.participant = participantObject;
      smash.participant = { ...participantObject, email: decodeURIComponent(participantObject?.email) };
    }
    if (participantObject?.firstName) {
      setFirstName(participantObject?.firstName);
      setCollectFirstNameRequired(false);
      smash.participant = participantObject;
    }
    if (participantObject?.lastName) {
      setLastName(participantObject?.lastName);
      setCollectLastNameRequired(false);
      smash.participant = participantObject;
    }
    if (participantObject?.phone) {
      setPhone(participantObject?.phone);
      setCollectPhoneRequired(false);
      smash.participant = participantObject;
    }
    if (
      participantObject?.email
      && participantObject?.firstName
      && participantObject?.lastName
      && participantObject?.phone
    ) {
      setEmail(participantObject?.email);
      setFirstName(participantObject?.firstName);
      setLastName(participantObject?.lastName);
      setPhone(participantObject?.phone);
      setShowContactData(false);
      smash.participant = participantObject;
    }

    // Bug 28192: Smash Detail does not show Email if incomplete results
    smashData.participant = smash.participant;
    const payload = getSessionPayload(smashData, start, null, 0, smashData?.smashPairs?.length,
      null, smashData?.participant);
    if (!preview) {
      Api.startSmashSession(smashData?.smashId, payload);
    }
    if (smashData?.hideSmashIntro) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (queryStringVal.pathname.includes('CustomSmashTheme')) {
      setPreviewCustomTheme(true);
    }
    const queryString = new URLSearchParams(queryStringVal.search);
    const trackId1 = queryString?.get('tid1');
    const trackId2 = queryString?.get('tid2');
    if (trackId1 !== null) {
      reactGA.initialize(trackId1);
      reactGA.pageview(window.location.pathname + window.location.search);
    }
    if (trackId2 !== null) {
      const options = {
        autoConfig: true, // set pixel's autoConfig.
        debug: false, // enable logs
      };
      reactfbp.init(trackId2, options);
      reactfbp.pageView();
    }

    if (!previewCustomTheme) {
      if (smashId) {
        Api.getExecution(smashId, !isEmpty(preview))
          .then(({ data }) => {
            setSmash(data);
            setCollectEmailRequired(data?.collectEmail);
            setCollectFirstNameRequired(data?.firstName);
            setCollectLastNameRequired(data?.lastName);
            setCollectPhoneRequired(data?.phone);
            setCustomTheme(data?.smashTheme);
            InitializeSmashSession(data);
            setIntroText(data.customizeSmashIntroText);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log('error', error);
          });
      } else {
        Api.getUserTracking().then((user) => {
          setCustomTheme(user.data.smashTheme);
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setShowTimer(smash?.smashDuration === 0 ? false : smash?.displaySmashTimer);
    if (previewCustomTheme) {
      setShowTimer(true);
    }
  }, [smash?.smashDuration, smash?.displaySmashTimer]);

  useEffect(() => {
    setShowContactData(false);
    if (smash?.collectEmail === true) {
      if (isEmpty(smash.participant?.email) === true) {
        setShowContactData(true);
      }
    }
    if (smash?.firstName === true) {
      if (isEmpty(smash.participant?.firstName) === true) {
        setShowContactData(true);
      }
    }
    if (smash?.lastName === true) {
      if (isEmpty(smash.participant?.lastName) === true) {
        setShowContactData(true);
      }
    }
    if (smash?.phone === true) {
      if (isEmpty(smash.participant?.phone) === true) {
        setShowContactData(true);
      }
    }
  }, [
    smash?.collectEmail,
    smash.participant?.email,
    smash?.firstName,
    smash.participant?.firstName,
    smash?.lastName,
    smash.participant?.lastName,
    smash?.phone,
    smash.participant?.phone,
  ]);

  useEffect(() => {
    setIsEmailValid(email.length > 0 && emailRegex.test(String(email).toLowerCase()));
    setIsFirstNameValid(firstName.length > 0 && nameRegex.test(firstName));
    setIsLastNameValid(lastName.length > 0 && nameRegex.test(lastName));
    setIsPhoneValid(phone.length > 10 && phone.length <= 12);
  }, [email, firstName, lastName, phone]);

  // function success(pos) {
  //   const {
  //     accuracy,
  //     altitude,
  //     altitudeAccuracy,
  //     heading,
  //     latitude,
  //     longitude,
  //     speed
  //   } = pos.coords;

  //   setGeoLocation({
  //     ...geoLocation,
  //     cords: {
  //       ...geoLocation.cords,
  //       accuracy,
  //       altitude,
  //       altitudeAccuracy,
  //       heading,
  //       latitude,
  //       longitude,
  //       speed
  //     },
  //     timestamp: new Date(pos.timestamp)
  //   });
  // }

  // function error(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

  // const fetchIPAddress = async () => {
  //   try {
  //     const response = await getIPAddress();
  //     setIpAdd(response.data.IPv4);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchIPAddress();
  //   navigator.geolocation.getCurrentPosition(success, error);

  //   const { connection = {} } = window.navigator;

  //   const {
  //     browserName,
  //     browserVersion,
  //     deviceType,
  //     engineName,
  //     engineVersion,
  //     fullBrowserVersion,
  //     osName,
  //     osVersion,
  //     mobileModel,
  //     mobileVendor
  //   } = rdd;

  //   if (smashId) {
  //     setUserinformation({
  //       ...userinformation,
  //       smashId,
  //       loggedInUserInformation: {
  //         ...userinformation.loggedInUserInformation,
  //       },
  //       connection: {
  //         ...userinformation.connection,
  //         downlink: connection.downlink || 0,
  //         effectiveType: connection.effectiveType || '',
  //         onchange: connection.onchange || null,
  //         rtt: connection.rtt || 0,
  //         saveData: connection.saveData || false
  //       },
  //       userAgent: {
  //         ...userinformation.userAgent,
  //         browserName,
  //         browserVersion,
  //         deviceType,
  //         engineName,
  //         engineVersion,
  //         fullBrowserVersion,
  //         osName,
  //         osVersion,
  //         mobileModel,
  //         mobileVendor,
  //       }
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (ipAdd) {
  //     setUserinformation({
  //       ...userinformation,
  //       ipAddress: ipAdd,
  //     });
  //   }
  // }, [ipAdd]);

  // useEffect(() => {
  //   if (geoLocation.cords.accuracy) {
  //     setUserinformation({
  //       ...userinformation,
  //       geolocation: geoLocation,
  //     });
  //   }
  // }, [geoLocation]);

  // useEffect(() => {
  //   console.log('userinformation', userinformation);
  //   if (userinformation.smashId && userinformation.ipAddress) {
  //     Api.getExecutionCreateUserInformation(smashId, userinformation)
  //       .then(data => console.log('res : ', data))
  //       .catch(err => console.log('Err :', err));
  //   }
  // }, [userinformation]);

  const isFormValid = () => {
    if (loading) return true;
    if (isLastStep) {
      if ((collectEmailRequired || email) && !isEmailValid) {
        return true;
      }
      if ((collectFirstNameRequired || firstName) && !isFirstNameValid) {
        return true;
      }
      if ((collectLastNameRequired || lastName) && !isLastNameValid) {
        return true;
      }
      if ((collectPhoneRequired || phone) && !isPhoneValid) {
        return true;
      }
      if (isEmailValid && isFirstNameValid && isLastNameValid && isPhoneValid) {
        return false;
      }
    }
  };

  const handleNext = async () => {
    if ((isPreviewMode && isLastStep) || (previewCustomTheme && isLastStep)) {
      history.goBack();
      return;
    }

    if (isLastStep) {
      if (showContactData || firstName || email || lastName || phone) {
        smash.participant = {
          email,
          firstName,
          lastName,
          phone,
        };
      }
      // Issue 32657  Global stats not figuring correctly
      const payload = getSessionPayload(
        smash,
        sessionStart,
        sessionEnd,
        selectedPairs,
        smash?.smashPairs?.length,
        null,
        smash.participant
      );
      if (!preview) {
        if (!previewCustomTheme) {
          await Api.updateSmashSession(smash?.smashId, payload);
          setStep(step + 1);
        }
      }
      return;
    }
    setStep(step + 1);
  };

  // Bug 33444: Presented smash pair count is coming less than actual presented count pair
  const onEndSession = async (pairCount) => {
    if (isCreate || previewCustomTheme) {
      setStep(step + 1);
      return;
    }

    if (step === 1) {
      const end = new Date().toISOString();
      setSessionEnd(end);
      // User Story 28071: save participants in smash session
      if (showContactData || firstName || email || lastName || phone) {
        smash.participant = {
          email,
          firstName,
          lastName,
          phone,
        };
      }
      // Bug 33444: Presented smash pair count is coming less than actual presented count pair
      const presentedPairCount = pairCount || selectedPairs;
      // Issue 32657  Global stats not figuring correctly
      const payload = getSessionPayload(
        smash,
        sessionStart,
        end,
        presentedPairCount,
        smash?.smashPairs?.length,
        null,
        smash.participant
      );
      if (!preview) {
        // Bug 33234: Rank in Result page is coming wrong, making it synchronous
        if (!previewCustomTheme) {
          await Api.updateSmashSession(smash?.smashId, payload);
        }
      }
      // If collectEmail is false then Show the completion message on Result page
      setStep(showContactData ? step + 1 : step + 2);
    }
  };

  return (
    <div
      style={{ background: customTheme?.background }}
      className={step !== 1 && step < 3 ? cx(styles.previewSection) : cx(styles.totalWidth)}
    >
      {loading && <div className="loader"></div>}
      {!loading && (
        <div className={`${styles.previewBody} ${!showTimer ? styles.occupyFullHeight : ''}`}>
          <div className={styles.closeBlock}>
            {(isCreate || previewCustomTheme) && (
              <p className={styles.closeIconSection}>
                <button
                  type="button"
                  className={styles.closeButton}
                  onClick={() => history.goBack()}
                >
                  <span>
                    <i className={cx(styles.closeicon, 'material-icons')}>clear</i>
                  </span>
                </button>
              </p>
            )}
          </div>
          {isFirstStep && (
            <>
              <div className={styles.main}>
                {smash.logoUrl ? (
                  <div>
                    <img className={styles.logoUrl} src={smash.logoUrl} alt="Logo" />
                    {smash.showRankSmashAffiliation && (
                      <>
                        <span className={styles.poweredBy}>powered by</span>
                        <img className={styles.poweredByLogoUrl} src="/RsLogoFull.png" alt="Logo" />
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <img className={styles.logoUrl} src="/RsLogoFull.png" alt="Logo" />
                  </div>
                )}
                <div className={styles.surveyBlock}>
                  <div
                    className={styles.name}
                    style={{ color: customTheme?.smashText }}
                    ref={(el) => {
                      if (el && customTheme?.smashText) {
                        el.style.setProperty('--underlineColor', customTheme?.smashText);
                      }
                    }}
                  >
                    {smash?.smashName ? smash?.smashName : 'SMASH EXAMPLE'}
                  </div>
                  <div className={styles.desc} style={{ color: customTheme?.smashText }}>
                    {introText}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* User Story 29695: Introduce inference into the Smash algorithm */}
          {previewCustomTheme && step === 1 ? (
            <SmashPairs
              list={defaultSmashPair}
              // Bug 33444: Presented smash pair count is coming less than actual presented count pair
              callback={onEndSession}
              isPreview={isCreate}
              previewCustomTheme={previewCustomTheme}
              customTheme={customTheme || {}}
              startMessage="START SMASH"
              setSelectedPairs={(value) => {
                setSelectedPairs(value);
              }}
            />
          ) : step === 1 && smash?.smashItems === null ? (
            <SmashPairs
              list={smash?.smashPairs}
              // Bug 33444: Presented smash pair count is coming less than actual presented count pair
              callback={onEndSession}
              isPreview={isCreate}
              customTheme={customTheme || {}}
              startMessage={smash?.smashStartMessage}
              setSelectedPairs={(value) => {
                setSelectedPairs(value);
              }}
            />
          ) : (
            step === 1
            && smash?.smashItems !== null && (
              <SmashPairsV2
                smashId={smash?.smashId}
                sessionId={smash?.sessionId}
                smashItemList={smash?.smashItems}
                // Bug 33444: Presented smash pair count is coming less than actual presented count pair
                callback={onEndSession}
                isPreview={isCreate}
                customTheme={customTheme || {}}
                startMessage={smash?.smashStartMessage}
                setSelectedPairs={(value) => {
                  setSelectedPairs(value);
                }}
              />
            )
          )}
          {step === 1 && (
            <Timer
              secs={previewCustomTheme ? 120 : smashDuration * 60}
              onTimeout={() => onEndSession()}
              showTimer={previewCustomTheme ? true : showTimer}
              customTheme={customTheme || {}}
            />
          )}
          {isLastStep && (
            // User Story: 35258 Segregating the Contact Collection Page and the Result Page components.
            <CollectContactdata
              smash={smash}
              customTheme={customTheme || {}}
              showContactData={showContactData}
              isFormValid={isFormValid}
              handleNext={handleNext}
              collectFirstNameRequired={collectFirstNameRequired}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              isFirstNameValid={isFirstNameValid}
              isLastNameValid={isLastNameValid}
              isEmailValid={isEmailValid}
              collectLastNameRequired={collectLastNameRequired}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              collectEmailRequired={collectEmailRequired}
              collectPhoneRequired={collectPhoneRequired}
              isPhoneValid={isPhoneValid}
            />
          )}
          {step === 3 && (
            <Results
              smashId={smashId}
              sessionId={smash?.sessionId}
              showSmashResult={showSmashResults}
              redirectUrl={smash?.redirectUrl}
              customTheme={customTheme || {}}
              collectEmail={smash?.collectEmail}
              smashEndMessage={smash?.smashEndMessage ? smash?.smashEndMessage : 'Completed'}
              email={email}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
            />
          )}
        </div>
      )}
      {step !== 1 && step < 3 && (
        <div className={styles.footer}>
          <div className={styles.buttonGroup}>
            <button
              ref={(el) => {
                if (el) {
                  el.style.setProperty('background-color', customTheme?.button, 'important');
                  // 41165: Apply buttonText value entered by user to the buttons on preview component
                  el.style.setProperty('color', customTheme?.buttonText, 'important');
                }
              }}
              disabled={isFormValid()}
              type="button"
              className={cx(styles.next, 'waves-effect waves-light btn-large')}
              onClick={handleNext}
            >
              {getStepText(step)}
            </button>
            <button
              ref={(el) => {
                if (el) {
                  el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                  // 41165: Apply buttonText value entered by user to the buttons on preview component
                  el.style.setProperty('color', customTheme?.buttonText, 'important');
                }
              }}
              disabled={isFormValid()}
              type="button"
              className={cx(styles.nextIcon, 'waves-effect waves-light btn-large')}
              onClick={handleNext}
            >
              <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Preview;
