/* eslint-disable linebreak-style */
import { toast } from 'react-toastify';
import '../index.css';

export const toggleToastInfo = (body) => {
  toast.info(body, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    closeButton: false
  });
};

export const copyToClipBoard = (str, body) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toggleToastInfo(body);
};

export const errorToast = (errorMessage) => {
  toast.error(errorMessage, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    closeButton: false
  });
};

export const togglePaidFeatureToast = (body) => {
  toast.info(body, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    closeButton: false,
    style: {
      backgroundColor: 'var(--light-pink)'
    }
  });
};

export const paidFeatureToast = (str, body) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  togglePaidFeatureToast(body);
};

export const toggleThemeSavedToast = (body) => {
  toast.info(body, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    closeButton: false,
    style: {
      backgroundColor: 'var(--blue)'
    }
  });
};

export const themeSavedToast = (str, body) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toggleThemeSavedToast(body);
};
