/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './IosSlider.module.css';

function IosSlider({ name, onChange, selected }) {
  const [value, setValue] = useState(selected);
  return (
    <div className={cx(styles.block, 'input-field')}>
      <input
        className={styles.hide}
        type="range"
        min="0"
        max="20"
        name={name}
        onChange={(e) => {
          setValue(e.target.value);
          onChange({ target: { name: e.target.name, value: +e.target.value } });
        }}
        value={value}
      />
    </div>
  );
}

IosSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export default IosSlider;
