/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const createSmash = (payload) => connector.post('/API/Smashes', payload);

const updateSingleSmash = (smashId, smashDetail) => connector.put(`/api/smashes/${smashId}`, smashDetail);

const getSmash = (smashId) => connector.get(`/API/Smashes/${smashId}`);

const archiveSmash = (smashId) => connector.delete(`/API/Smashes/${smashId}/archive`);

const createOption = (payload, id) => connector.post(`/Api/Smashes/${id}/option`, payload);

const deleteOption = (smashId, itemId) => connector.delete(`/Api/Smashes/${smashId}/option/${itemId}`);

const createConfig = (payload, id) => connector.post(`/Api/Smashes/${id}/configuration`, payload);

// User Story 29695: Introduce inference into the Smash algorithm
const getExecution = (smashId, isPreview) => connector.get(
  `/Api/v2/SmashExecution/${smashId}${isPreview ? '?preview=true' : ''}`
);

const updateSmashPair = (smashId, sessionId, payload) => connector.post(
  `/Api/Smashes/${smashId}/session/${sessionId}/selection`, payload
);

const startSmashSession = (smashId, payload) => connector.post(
  `/Api/Smashes/${smashId}/session`, payload
);

const updateSmashSession = (smashId, payload) => {
  console.log('updateSmashSession', payload);
  return connector.put(
    `/Api/Smashes/${smashId}/session`, payload
  );
};

const getSmashExecutionResults = (smashId, sessionId) => connector.get(
  `/Api/SmashAnalysis/${smashId}/ParticipantResult/${sessionId}`
);

//  User Story 33781: Pass collected contact data in the URL redirect
const getParticipantContactData = (smashId, sessionId) => connector.get(
  `Api/SmashAnalysis/${smashId}/ParticipantContactData/${sessionId}`
);

// const getExecutionCreateUserInformation = (smashId, payload) => connector.post(
//   `/Api/v2/SmashExecution/${smashId}/CreateCapturedUserInformation`, payload
// );

const smash = {
  createSmash,
  updateSingleSmash,
  createOption,
  createConfig,
  deleteOption,
  getExecution,
  updateSmashPair,
  startSmashSession,
  updateSmashSession,
  getSmashExecutionResults,
  getParticipantContactData,
  getSmash,
  archiveSmash,
  // getExecutionCreateUserInformation
};

export default smash;
