export const defaultSmashPair = [
  {
    smashPairId: null,
    sessionId: '629de971596e360e956d4602',
    smashId: '62946e2f2134914f0e1a3103',
    item1: {
      smashItemId: '62946e432134914f0e1a315f',
      imageTag: 'C',
      smashItemText: 'C',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    item2: {
      smashItemId: '62946e352134914f0e1a3139',
      imageTag: 'A',
      smashItemText: 'A',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    winnerItem: null,
    winnerDecisionTime: null,
    item1DisplayOrder: 1,
    item2DisplayOrder: 2,
    generatedFrom: 0
  },
  {
    smashPairId: null,
    sessionId: '629de971596e360e956d4602',
    smashId: '62946e2f2134914f0e1a3103',
    item1: {
      smashItemId: '62946e3c2134914f0e1a319d',
      imageTag: 'B',
      smashItemText: 'B',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    item2: {
      smashItemId: '62946e352134914f0e1a3139',
      imageTag: 'A',
      smashItemText: 'A',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    winnerItem: null,
    winnerDecisionTime: null,
    item1DisplayOrder: 1,
    item2DisplayOrder: 2,
    generatedFrom: 0
  },
  {
    smashPairId: null,
    sessionId: '629de971596e360e956d4602',
    smashId: '62946e2f2134914f0e1a3103',
    item1: {
      smashItemId: '62946e432134914f0e1a315f',
      imageTag: 'C',
      smashItemText: 'C',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    item2: {
      smashItemId: '62946e3c2134914f0e1a319d',
      imageTag: 'B',
      smashItemText: 'B',
      imageURL: null,
      imageDisplayName: null,
      isArchive: false
    },
    winnerItem: null,
    winnerDecisionTime: null,
    item1DisplayOrder: 1,
    item2DisplayOrder: 2,
    generatedFrom: 0
  }
];
