import { api as connector } from './axios';
// Bug 34181: All duplicate sessions is contain the
// Top 5 Ranked item value of first session on Group Session list screen
const getSessionAnalysis = (smashId, sessionId) => connector.get(
  `/API/SmashAnalysis/${smashId}/SessionInsights/${sessionId}`
);

const participantAnalysis = {
  getSessionAnalysis
};

export default participantAnalysis;
