/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const getSmashInsights = (payload) => connector.get(`/API/SmashAnalysis/${payload}/SmashInsight/v2`, payload);

const smashInsights = {
  getSmashInsights
};

export default smashInsights;
