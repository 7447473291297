import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from '../createSmash/Create.module.css';

function CustomSmashThemeHeader({ navigateAfterSavingTheme }) {
  return (
    <div className="smash-header">
      <h6 className="smash-headerh1">
        <span className="header-icon">
          <button
            type="button"
            className={styles.backBtn}
            onClick={navigateAfterSavingTheme}
          >
            <i
              className={cx(styles.backIcon, 'fa fa-angle-left')}
              aria-hidden="true"
            >
            </i>
          </button>
          Customize Smash Theme
        </span>
      </h6>
    </div>
  );
}

CustomSmashThemeHeader.propTypes = {
  navigateAfterSavingTheme: PropTypes.func.isRequired
};

export default CustomSmashThemeHeader;
