import { combineReducers } from 'redux';
import authReducer from './authReducer';
import customThemeReducer from './customThemeReducer';
// import smashConfigurationReducer from './smashConfigurationReducer';

export default combineReducers({
  auth: authReducer,
  customTheme: customThemeReducer
  // smashConfiguration: smashConfigurationReducer
});
