/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable linebreak-style */
import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DynamicFont from 'react-dynamic-font';
import cx from 'classnames';
import Api from 'api';
import { copyToClipBoard, errorToast } from 'utils/Toast';
import ToolTip from 'components/shared/ToolTip';
import { getInfoText } from 'components/createSmash/Utils/Utils';
import { ValidateImage, ValidateImageUrl } from '../utils/imageUtils';
import 'react-toastify/dist/ReactToastify.css';
import './SmashListing.css';
import ConfirmationBox from './shared/ConfirmationBox';
import styles from './createSmash/AddItems.module.css';
import { SmashStatus } from '../Constants/Constant';
import { signoutRedirect } from '../userService';

class SmashListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smashes: [],
      googleTrackingId: '',
      facebookTrackingId: '',
      webhookUrl: '',
      logoUrl: '',
      logoImage: {},
      showSettings: false,
      updateFlag: false,
      isLoading: true,
      openConfirmBox: false,
      selectedSmashId: 0,
      isMenuOpened: false,
    };
    this.inputRef = createRef();
  }

  async componentDidMount() {
    const { data } = await Api.getSmashes();
    const userSettings = await Api.getUserTracking();

    this.setState({
      smashes: data,
      googleTrackingId: userSettings.data.googleTrackingId,
      facebookTrackingId: userSettings.data.facebookTrackingId,
      logoUrl: userSettings.data.logoUrl,
      webhookUrl: userSettings.data.webhookUrl,
      updateFlag:
        userSettings.data.googleTrackingId !== '' ||
        userSettings.data.facebookTrackingId !== '' ||
        userSettings.data.logoUrl !== '' ||
        userSettings.data.logoImage !== {},
      isLoading: false,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { logoImage, logoUrl } = this.state;
    const formData = new FormData();
    if (logoImage !== prevState.logoImage) {
      formData.append('logoImage', logoImage);
      await this.updateLogoUrl(formData);
    }
    if (logoUrl !== prevState.logoUrl) {
      formData.append('logoUrl', logoUrl);
      await this.updateLogoUrl(formData);
    }
  }

  handleClick() {
    this.setState((prevState) => {
      const { isMenuOpened } = prevState;
      return {
        ...prevState,
        isMenuOpened: !isMenuOpened
      };
    });
  }

  uploadLogo = async (file) => {
    if (ValidateImage(file)) {
      this.setState({ logoImage: file });
    } else {
      errorToast('Please upload valid image file !!');
    }
  };

  uploadLogoUrl = async (fileUrl) => {
    if (ValidateImageUrl(fileUrl)) {
      this.setState({ logoUrl: fileUrl });
    } else {
      errorToast('Please provide valid image url !!');
    }
  }

  updateLogoUrl = async (formData) => {
    await Api.updateUserTracking(formData);
    await Api.getUserTracking().then(({ data }) => {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        logoUrl: data.logoUrl,
      });
    });
  }

  handleClose = async (value) => {
    if (!value) {
      this.setState((prevState) => ({ ...prevState, openConfirmBox: false }));
      return;
    }
    const { selectedSmashId } = this.state;
    this.setState((prevState) => {
      const { selectedSmashId, smashes } = prevState;
      return {
        ...prevState,
        smashes: smashes.filter((x) => x.smashId !== selectedSmashId),
        openConfirmBox: false,
      };
    });
    await Api.archiveSmash(selectedSmashId);
  };

  showLoader = () => (
    <div className="smash-body">
      <div className="loader"></div>
    </div>
  );

  handleOpen = (smashId) => {
    this.setState((prevState) => ({
      ...prevState,
      openConfirmBox: true,
      selectedSmashId: smashId,
    }));
  };

  formatString(str) {
    return str.replace(/\s+/g, '');
  }

  submitTracking() {
    const {
      showSettings,
      googleTrackingId,
      facebookTrackingId,
      webhookUrl,
      logoUrl,
      updateFlag,
      logoImage,
    } = this.state;
    if (showSettings) return;
    const formData = new FormData();
    formData.append('GoogleTrackingId', googleTrackingId);
    formData.append('FacebookTrackingId', facebookTrackingId);
    formData.append('webhookUrl', webhookUrl);
    formData.append('LogoUrl', logoUrl);
    formData.append('logoImage', logoImage);
    if (updateFlag) {
      Api.updateUserTracking(formData);
    } else {
      Api.createUserTracking(formData);
    }
  }

  toggleSetting() {
    this.setState(
      (state) => ({
        showSettings: !state.showSettings,
      }),
      this.submitTracking
    );
  }

  render() {
    const {
      smashes,
      showSettings,
      googleTrackingId,
      facebookTrackingId,
      webhookUrl,
      isLoading,
      openConfirmBox,
      logoUrl,
      logoImage,
      isMenuOpened,
    } = this.state;
    const button = (
      <div className="flex">
        <span className="width80">Link Copied</span>
        <button type="button" className="closeButton">
          <span>
            <i className={cx('closeicon', 'material-icons')}>clear</i>
          </span>
        </button>
      </div>
    );
    const notify = (str) => {
      copyToClipBoard(str, button);
    };

    return (
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* smash-block starts  */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh1">
                  <span className="header-icon">
                    <i
                      className="material-icons"
                      style={{ top: '0px', cursor: 'pointer' }}
                      onClick={() => this.handleClick()}
                    >
                      {isMenuOpened ? 'close' : 'menu'}
                    </i>
                    <ul className={isMenuOpened ? 'nav-menu active' : 'nav-menu'}>
                      <li id="pdo_feedback" className="">
                        <i
                          className="material-icons"
                          style={{
                            cursor: 'pointer'
                          }}
                          role="button"
                          tabIndex="-1"
                          id="feedbackId"
                        >
                          feedback
                        </i>
                        <span>
                          Feedback
                        </span>
                      </li>
                      <li>
                        <i
                          className="material-icons"
                          style={{
                            cursor: 'pointer'
                          }}
                          role="button"
                          onClick={() => signoutRedirect()}
                          tabIndex="-1"
                        >
                          logout
                        </i>
                        <span onClick={() => signoutRedirect()}>
                          Logout
                        </span>
                      </li>
                    </ul>
                    RankSmash
                  </span>
                </h6>
              </div>
              {isLoading ? (
                this.showLoader()
              ) : (
                <div className="smash-body">
                  <div className="smash-lists-block">
                    <h5>
                      All Smash
                      <span className="header-breadcrumb">
                        <i
                          className="material-icons"
                          style={{
                            cursor: 'pointer',
                            fontSize: '26px',
                            float: 'right',
                          }}
                          role="button"
                          onClick={() => this.toggleSetting()}
                          onKeyPress={this.handleKeyPress}
                          tabIndex="0"
                        >
                          settings
                        </i>
                      </span>
                    </h5>
                    {showSettings && (
                      <>
                        <div className="global-setting-div">
                          <p>Global Settings</p>
                        </div>
                        <div className={cx('block', 'input-field', 'removeMargins')}>
                          <div>
                            <input
                              className="field"
                              type="text"
                              placeholder="Google TrackingId"
                              id="googleTrackId"
                              onChange={(e) => {
                                this.setState({
                                  googleTrackingId: e.target.value,
                                });
                              }}
                              value={googleTrackingId}
                              maxLength="30"
                              onBlur={(e) => {
                                if (e.target.value.length === 0) {
                                  e.target.classList.add('error');
                                } else {
                                  e.target.classList.remove('error');
                                }
                              }}
                            />
                          </div>
                          <div>
                            <input
                              className="field"
                              type="text"
                              placeholder="Facebook TrackingId"
                              id="facebookTrackId"
                              onChange={(e) => {
                                this.setState({
                                  facebookTrackingId: e.target.value,
                                });
                              }}
                              value={facebookTrackingId}
                              maxLength="30"
                              onBlur={(e) => {
                                if (e.target.value.length === 0) {
                                  e.target.classList.add('error');
                                } else {
                                  e.target.classList.remove('error');
                                }
                              }}
                            />
                          </div>
                          <div>
                            <input
                              className="field"
                              type="text"
                              placeholder="Webhook URL"
                              id="webhookUrl"
                              onChange={(e) => {
                                this.setState({
                                  webhookUrl: e.target.value,
                                });
                              }}
                              value={webhookUrl}
                              onBlur={(e) => {
                                if (e.target.value.length === 0) {
                                  e.target.classList.add('error');
                                } else {
                                  e.target.classList.remove('error');
                                }
                              }}
                            />
                          </div>
                          {!logoUrl ? (
                            <div>
                              <div>
                                <input
                                  className="field"
                                  type="text"
                                  placeholder="Your Logo URL"
                                  id="logoUrl"
                                  onBlur={(e) => {
                                    const file = e.target.value;
                                    if (file && !(file.length === 0)) this.uploadLogoUrl(file);
                                  }}
                                />
                              </div>
                              <span className="sub-header text-left">or</span>
                              <div className="add-logo-link">
                                <a
                                  aria-hidden="true"
                                  onClick={() => this.inputRef.current.click()}
                                >
                                  Add Logo
                                  <div
                                    role="presentation"
                                    className={styles.iconSection}
                                  >
                                    <input
                                      accept=".jpeg,.jpg,.png"
                                      ref={this.inputRef}
                                      className={styles.hide}
                                      type="file"
                                      onChange={(e) => {
                                        const fileList = e.target.files[0];
                                        this.uploadLogo(fileList);
                                      }}
                                    />
                                  </div>
                                  {logoImage?.name?.length > 0 && (
                                    <div className="add-logo">
                                      {`Added - ${logoImage.name}`}
                                    </div>
                                  )}
                                </a>
                              </div>
                              <div className="logo-tooltip">
                                <ToolTip
                                  label=""
                                  text={getInfoText('addImage')}
                                  icon="help_outline"
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span className="sub-header">Uploaded Logo</span>
                              <div className="uploaded-logo">
                                <div className="uploaded-logo-image">
                                  <img
                                    className="logoUrl"
                                    src={logoUrl}
                                    alt="logo"
                                  />
                                </div>
                                <div className="uploaded-logo-delete">
                                  <i
                                    role="presentation"
                                    onClick={() => {
                                      Api.deleteSmashLogo().then((res) => {
                                        if (res.status === 200) {
                                          this.setState({
                                            logoUrl: '',
                                            logoImage: {},
                                          });
                                        }
                                      });
                                    }}
                                    className="material-icons delete-icon"
                                  >
                                    delete
                                  </i>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className={cx('customize-smash-theme-div', 'add-logo-link')}>
                            <Link
                              to={{
                                pathname: '/CustomSmashTheme'
                              }}
                            >
                              Customize Smash Theme
                            </Link>
                          </div>
                          <hr />
                        </div>
                      </>
                    )}
                    {smashes.map((smash, key) => (
                      <div className="smash-list-div" key={key}>
                        <Link
                          to={{
                            pathname: '/SmashDetail',
                            state: { data: smash },
                          }}
                        >
                          <h5><DynamicFont content={smash.name} /></h5>
                          <p key={smash.smashid}>
                            {`Created on : ${moment(
                              smash.creationDateTime
                            ).format('DD-MMM-YYYY')}`}
                          </p>
                          <p key={smash.smashid}>
                            {smash?.status?.toLowerCase() ===
                            SmashStatus.Complete
                              ? `Ended on : ${moment(smash.endDateTime).format(
                                'DD-MMM-YYYY'
                              )}`
                              : ''}
                          </p>
                        </Link>
                        <div className="button-edit-share">
                          <Link
                            className="waves-effect waves-light"
                            to={{
                              pathname: '/create',
                              state: { data: smash?.smashId },
                            }}
                          >
                            <i className="material-icons editButton">edit</i>
                          </Link>
                        </div>
                        {smash?.status?.toLowerCase() === SmashStatus.Active ? (
                          <div className="button-edit-share">
                            <Link
                              className="waves-effect waves-light"
                              role="button"
                              to="#"
                              onClick={() => notify(
                                this.formatString(
                                  `${window.location.origin}/SmashExecution/
                              ${smash.smashId}?tid1=${googleTrackingId}
                              &tid2=${facebookTrackingId}`
                                )
                              )}
                            >
                              <i className="material-icons editButton">share</i>
                            </Link>
                          </div>
                        ) : (
                          ''
                        )}
                        {smash?.status?.toLowerCase() === SmashStatus.Draft
                          || smash?.status?.toLowerCase() === SmashStatus.Complete ? (
                            <div className="button-edit-share">
                              <Link
                                className="waves-effect waves-light"
                                role="button"
                                to="#"
                                onClick={() => this.handleOpen(smash?.smashId)}
                              >
                                <i className="material-icons editButton">inventory</i>
                              </Link>
                            </div>
                          ) : (
                            ''
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="smash-footer">
                <div className="create-smash-block">
                  <Link
                    to="/create"
                    className="waves-effect btn-large create-smash-btn"
                  >
                    <i className="material-icons">add</i>
                    {' '}
                    Create Smash
                  </Link>
                </div>
              </div>
            </div>
            {/* smash-block ends */}
            <ConfirmationBox
              isOpen={openConfirmBox}
              onClose={this.handleClose}
              objectNameToDelete="Smash"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SmashListing;
