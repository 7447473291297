/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Api from 'api';
import { isEmpty } from 'lodash';
import {
  Link,
  useHistory,
  useLocation,
  useParams
}
from 'react-router-dom';
import AddItems from 'components/createSmash/AddItems';
import IosSwitch from 'components/shared/IosSwitch';
import IosSlider from 'components/shared/IosSlider';
import ToolTip from 'components/shared/ToolTip';
import { copyToClipBoard, toggleToastInfo } from 'utils/Toast';
import {
  Grid
} from '@material-ui/core';
import noItems from 'assets/images/No_items.svg';
import Done from 'assets/images/Done.svg';
import { getInfoText } from 'components/createSmash/Utils/Utils';
import { getUserTierInfo } from 'components/shared/tierpermissions/Tier';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import styles from './Create.module.css';
import { paidFeatureToast } from '../../utils/Toast';
import { signinRedirect } from '../../userService';
import cookieService from '../../cookieService';
import ConfirmationBox from '../shared/ConfirmationBox';

function Create({ onClose }) {
  const params = useParams();
  let defaultIntroText = 'When you click ‘Start Smash’ below, you\'ll pick one ';
  defaultIntroText += 'of two choices until you’ve run out of time, or have reviewed all the possible ';
  defaultIntroText += 'pairs of items. Pick the item that best answers the question ';
  defaultIntroText += 'you will see at the top of the screen.';
  const [step, setStep] = useState(parseInt((params.step || 0), 10));
  const [openItem, setOpenItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [settings, setUserSettings] = useState([]);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    smashId: params.smashId || 0,
    name: '',
    description: '',
    smashItems: [],
    status: '',
    creationDateTime: null,
    customizeSmashIntroText: defaultIntroText,
    smashConfiguration: {
      smashDuration: 5,
      smashCompletionMessage: '',
      showSmashTimerToParticipant: false,
      showSmashResultToParticipant: false,
      askForParticipantEmail: false,
      askForParticipantFirstName: false,
      askForParticipantLastName: false,
      askForParticipantPhone: false,
      showWebhookUrl: false,
      webHookUrl: '',
      showRedirectUrl: false,
      redirectUrl: '',
      hideSmashIntro: false,
      // showCustomTheme: false,
      applyCustomTheme: false
    },
  });

  const button = (
    <div className="flex">
      <span className="width80">Link Copied</span>
      <button
        type="button"
        className="closeButton"
      >
        <span><i className={cx('closeicon', 'material-icons')}>clear</i></span>
      </button>
    </div>
  );

  const user = cookieService.get('user');

  const notify = (str) => {
    copyToClipBoard(str, button);
  };
  function login() {
    cookieService.set('smashId', state.smashId);
    signinRedirect();
  }

  useEffect(() => {
    const id = location.state?.data || state.smashId;
    setIsLoading(true);
    if (user) {
      Api.getUserTracking().then(({ data }) => {
        setUserSettings(data);
      });
    }

    if (id) {
      Api.getSmash(id).then(({ data }) => {
        data = {
          ...data,
          smashConfiguration: data.smashConfiguration || state.smashConfiguration,
          smashItems: data.smashItems || state.smashItems
        };
        setState({ ...state, ...data });
        setIsLoading(false);
      })
        .catch(error => {
          console.log('error', error);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const id = location.state?.data || state.smashId;
    if (settings.smashTheme && settings.userTier !== 0) {
      const empty = Object.values(settings.smashTheme).some(e => e !== '');
      if (empty && !id) {
        setState({ ...state, smashConfiguration: { ...state.smashConfiguration, applyCustomTheme: true } });
      }
    }
  }, [settings]);

  useEffect(() => {
    if (!state.smashConfiguration.showWebhookUrl && state.smashConfiguration.webHookUrl) {
      setState({ ...state, smashConfiguration: { ...state.smashConfiguration, webHookUrl: '' } });
    }
    if (!state.smashConfiguration.showRedirectUrl && state.smashConfiguration.redirectUrl) {
      setState({ ...state, smashConfiguration: { ...state.smashConfiguration, redirectUrl: '' } });
    }
  }, [state.smashConfiguration.showWebhookUrl, state.smashConfiguration.showRedirectUrl]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (state.smashConfiguration.askForParticipantEmail || state.smashConfiguration.askForParticipantFirstName
      || state.smashConfiguration.askForParticipantLastName || state.smashConfiguration.askForParticipantPhone) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [state.smashConfiguration.askForParticipantEmail, state.smashConfiguration.askForParticipantFirstName,
    state.smashConfiguration.askForParticipantLastName, state.smashConfiguration.askForParticipantPhone]);

  const handleSmashConfigurationChange = ({ target }) => {
    const { name, value } = target;
    console.log('name', name);
    console.log('value', value);
    setState({ ...state, smashConfiguration: { ...state.smashConfiguration, [name]: value } });
  };

  const handleCheckedChange = ({ target }) => {
    const { name, checked } = target;
    setState({ ...state, smashConfiguration: { ...state.smashConfiguration, [name]: checked } });
  };

  const createSmash = async () => {
    setIsLoading(true);
    const { data } = await Api.createSmash({
      name: state.name, description: state.description, customizeSmashIntroText: state.customizeSmashIntroText
    });
    setIsLoading(false);
    if (isEmpty(data.smashId)) {
      console.error('smash creation failed');
    } else {
      setState({
        ...state, smashId: data.smashId, creationDateTime: data.creationDateTime, status: data.status
      });
      setStep(1);
    }
  };

  const updateSmash = async () => {
    setIsLoading(true);
    await Api.updateSingleSmash(state.smashId, state);
    setIsLoading(false);
    if (isEmpty(state.smashId)) {
      console.error('smash creation failed');
    } else {
      setState({ ...state, smashId: state.smashId });
      setStep(1);
    }
  };

  const body = (
    <div className="flex">
      <span className="width80">
        Your smash needs a minimum of 3 items. It’s not a race if there is only one runner.
      </span>
      <button type="button" className="btn-flat toast-action toast-close">X</button>
    </div>
  );

  const createSmashConfig = async () => {
    const { data: { smashConfiguration } } = await Api.createConfig(state.smashConfiguration, state.smashId);
    // console.log('POST', smashConfiguration);
    if (isEmpty(smashConfiguration)) {
      console.error('smash config creation failed');
    } else {
      setStep(3);
    }
  };
  // User Story 40085: Paid feature popup it should say Paid feature ! upgrade now
  const paidFeatureToastButton = (
    <div className="flex">
      <a
        style={{ color: 'white' }}
        className="width80"
        href="https://www.ranksmash.com/#pricing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Paid Feature!
        <span className={styles.name}> upgrade now.</span>
      </a>
      <button
        type="button"
        className="closeButton"
      >
        <span><i className={cx('closeicon', 'material-icons')}>clear</i></span>
      </button>
    </div>
  );

  const preserveSmashLocation = async () => {
    await createSmashConfig();
    const redirectPath = `/CreateSmashConfiguration/${state.smashId}/${step}`;
    history.push(redirectPath);
    history.push(`/SmashExecution/${state.smashId}/preview`);
  };

  const getStepText = () => {
    if (step === 2) {
      return 'SAVE';
    }
    if (step === 3) {
      return 'DONE';
    }
    return 'NEXT';
  };

  const handleNext = () => {
    if (step === 0) {
      if (state.smashId !== 0) {
        updateSmash();
        setStep(1);
      } else {
        createSmash();
      }
    } else if (step === 1) {
      if (state.smashItems.length < 3) {
        toggleToastInfo(body);
        return;
      }
      setStep(2);
    } else if (step === 2) {
      createSmashConfig();
    } else if (step === 3) {
      history.push('/SmashListing');
    }
  };

  const disableSteps = () => {
    if (step === 0) {
      return state.name.trim().length === 0 || state.description.trim().length === 0
      || state.customizeSmashIntroText.trim().length === 0;
    }
    if (step === 1) {
      return state.smashItems.length === 0;
    }
    if (step === 2) {
      return !state.smashConfiguration.smashCompletionMessage;
    }
    if (step === 3) {
      return false;
    }
    return true;
  };

  const toggleBack = () => {
    const prevStep = step - 1;
    if (prevStep === -1) {
      if (!onClose) {
        history.push('/SmashListing');
        return;
      }
      onClose();
    } else {
      setStep(prevStep);
    }
  };

  const getHeader = () => {
    switch (step) {
      case 0:
        return 'Create Smash';
      case 1:
        return 'Add Items';
      case 2:
        return 'Configure Smash';
      case 3:
        return 'Share Smash';
      default:
        return 'Create Smash';
    }
  };

  const onAddItem = (item) => {
    const isItemAlreadyExists = state.smashItems.some(x => x.smashItemId === item.smashItemId);

    if (isItemAlreadyExists) {
      const smashItems = state.smashItems.map(x => {
        if (x.smashItemId === item.smashItemId) {
          return { ...x, ...item };
        }
        return x;
      });

      setState({ ...state, smashItems });
    } else {
      setState({ ...state, smashItems: [...state.smashItems, item] });
    }
    setOpenItem(false);
  };

  const handleClose = async (value) => {
    if (!value) {
      setOpenConfirmBox(false);
      return;
    }

    const filtered = state.smashItems.filter(x => x.smashItemId !== selectedItem.smashItemId);
    setState({ ...state, smashItems: filtered });
    setOpenConfirmBox(false);
    await Api.deleteOption(state.smashId, selectedItem.smashItemId);
  };

  const preservingState = async (e) => {
    e.preventDefault();
    await Api.updateSingleSmash(state.smashId, state);
    history.push(`/CustomSmashTheme/${state.smashId}/${step}`);
  };

  const delItem = async (item) => {
    setSelectedItem(item);
    setOpenConfirmBox(true);
  };

  const checkIfAllowed = (input) => {
    switch (input) {
      case 'itemRedirectURL': {
        switch (getUserTierInfo(`${settings.userTier}`, 'itemRedirectURL')) {
          case true:
            paidFeatureToast('Paid Feature', paidFeatureToastButton);
            break;
          case false:
            break;
          default:
            break;
        }
        break;
      }
      case 'customizeSmashIntroText': {
        switch (getUserTierInfo(`${settings.userTier}`, 'customizeSmashIntroText')) {
          case true:
            paidFeatureToast('Paid Feature', paidFeatureToastButton);
            break;
          case false:
            break;
          default:
            break;
        }
        break;
      }
      case 'hideSmashIntro': {
        switch (getUserTierInfo(`${settings.userTier}`, 'hideSmashIntro')) {
          case true:
            paidFeatureToast('Paid Feature', paidFeatureToastButton);
            break;
          case false:
            break;
          default:
            break;
        }
        break;
      }
      case 'showGlobalRedirectURL': {
        switch (getUserTierInfo(`${settings.userTier}`, 'showGlobalRedirectURL')) {
          case true:
            paidFeatureToast('Paid Feature', paidFeatureToastButton);
            break;
          case false:
            break;
          default:
            break;
        }
        break;
      }
      case 'applyCustomTheme': {
        switch (getUserTierInfo(`${settings.userTier}`, 'applyCustomTheme')) {
          case true:
            paidFeatureToast('Paid Feature', paidFeatureToastButton);
            break;
          case false:
            break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  };

  if (openItem) {
    return (
      <AddItems
        smashId={state.smashId}
        selectedItem={selectedItem}
        onAdd={onAddItem}
        checkIfAllowed={checkIfAllowed}
        onBack={() => {
          setSelectedItem(null);
          setOpenItem(false);
        }} />
    );
  }

  const formatString = (str) => str.replace(/\s+/g, '');

  return (
    <div className={styles.block}>
      <div className={styles.header}>
        <h6 className={styles.h6}>
          <span className={styles.icon}>
            <button
              type="button"
              className={styles.backBtn}
              onClick={() => toggleBack()}
            >
              <i
                className={cx(styles.backIcon, 'fa fa-angle-left')}
                aria-hidden="true"
              >
              </i>
            </button>
            {getHeader(step)}
          </span>
          <span className={styles.breadcrumb}>
            {`${step + 1 === 4 ? 5 : step + 1
            }/5`}

          </span>
        </h6>
      </div>
      <div className={styles.body}>
        {isLoading ? (
          <div className="backdroploader">
            <div className="loader"></div>
          </div>
        ) : ''}
        {step === 0 && (
          <>
            <div className={cx(styles.block, 'input-field')}>
              <ToolTip label="Name your Smash" text={getInfoText('name')} icon="help_outline" />
              <input
                className={styles.field}
                type="text"
                placeholder="Name (public facing)"
                id="name"
                name="name"
                onChange={handleChange}
                value={state.name}
                maxLength="30"
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.classList.add('error');
                  } else {
                    e.target.classList.remove('error');
                  }
                }}
              />
            </div>
            <div className={cx(styles.block, 'input-field', styles.inputDivWithBorder)}>
              <ToolTip
                label="Comparison question"
                text={getInfoText('description')}
                icon="help_outline"
              />
              <textarea
                rows={5}
                className={cx('materialize-textarea', styles.textarea)}
                placeholder="Which option below is MOST IMPORTANT / your BIGGEST PAIN POINT / BETTER… ?"
                id="description"
                name="description"
                maxLength="140"
                onChange={handleChange}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.className += ' error';
                  } else {
                    e.target.classList.remove('error');
                  }
                }}
                value={state.description}
              >
              </textarea>
            </div>
            <div className={cx(styles.block, 'input-field', styles.inputDivWithBorder)}>
              <ToolTip
                label="Customize Smash Intro Text"
                text={getInfoText('customizeSmashIntroText')}
                icon="help_outline"
              />
              <textarea
                rows={5}
                className={cx('materialize-textarea', styles.textarea)}
                placeholder=""
                id="customizeSmashIntroText"
                name="customizeSmashIntroText"
                maxLength="140"
                onChange={handleChange}
                readOnly={settings?.userTier === 0}
                onClick={() => checkIfAllowed('customizeSmashIntroText')}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.className += ' error';
                  } else {
                    e.target.classList.remove('error');
                  }
                }}
                value={state.customizeSmashIntroText}
              >
              </textarea>
            </div>
            <div className={cx(styles.block, styles.iosBlock)}>
              <ToolTip label="Hide Smash Intro" text={getInfoText('hideSmashIntro')} icon="help_outline" />
              <IosSwitch
                name="hideSmashIntro"
                checked={state.smashConfiguration.hideSmashIntro}
                onChange={handleCheckedChange}
                onClick={() => checkIfAllowed('hideSmashIntro')}
                disable={getUserTierInfo(`${settings.userTier}`, 'hideSmashIntro')}
              />
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className={styles.itemsHeaderSection}>
              <ToolTip label="Added Items" text={getInfoText('items')} icon="help_outline" />
              <span className={styles.items}>
                {`${state.smashItems.length} item/s`}
              </span>
            </div>
            {state.smashItems.length === 0 && (
              <div className={styles.noItem}>
                <img src={noItems} alt="img" className={styles.responsive} />
              </div>
            )}
            <ConfirmationBox
              isOpen={openConfirmBox}
              onClose={handleClose}
              objectNameToDelete="Item"
            />
            {state.smashItems.length > 0 && (
              <Grid container className={styles.addedItems}>
                {state.smashItems.map((x, i) => (
                  <Grid item xs={12} key={i} className={styles.addedItem}>
                    <Grid item xs={1} className={styles.f24}>
                      {`#${i + 1}`}
                    </Grid>
                    <Grid item xs={9} className={styles.f18}>
                      {x.imageTag}
                    </Grid>
                    <Grid item xs={2}>
                      <i
                        role="presentation"
                        onClick={() => {
                          setSelectedItem(x);
                          setOpenItem(true);
                        }}
                        className={cx('material-icons', styles.delIcon)}
                      >
                        edit
                      </i>
                    </Grid>
                    <Grid item xs={2}>
                      <i
                        role="presentation"
                        onClick={() => delItem(x)}
                        className={cx('material-icons', styles.delIcon)}
                      >
                        delete
                      </i>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            <div className={styles.additems}>
              <button
                type="button"
                onClick={() => {
                  setSelectedItem(null);
                  setOpenItem(true);
                }}
                className={cx(
                  styles.addItemsBtn,
                  'waves-effect btn-large transparent-btn'
                )}
              >
                Add Items
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={cx(styles.iosSlider)}>
              <ToolTip label="Display Timer" text={getInfoText('timer')} icon="help_outline" />
              <div className={styles.width80}>
                <IosSwitch
                  name="showSmashTimerToParticipant"
                  checked={state.smashConfiguration.showSmashTimerToParticipant}
                  onChange={handleCheckedChange}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className={cx(styles.iosSlider)}>
              <ToolTip
                label={`${state.smashConfiguration.smashDuration} mins`}
                text={getInfoText('timerDuration')}
                icon="help_outline"
              />
              <div className={styles.width80}>
                <IosSlider
                  name="smashDuration"
                  selected={state.smashConfiguration.smashDuration || 5}
                  onChange={handleSmashConfigurationChange}
                />
              </div>
            </div>
            <div className={cx('input-field')}>
              <ToolTip
                label="Completion message"
                text={getInfoText('completion')}
                icon="help_outline"
              />
              <textarea
                rows={5}
                className={cx('materialize-textarea', styles.textarea)}
                placeholder="Completion message"
                id="smashCompletionMessage"
                name="smashCompletionMessage"
                maxLength="140"
                onChange={handleSmashConfigurationChange}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.className += ' error';
                  } else {
                    e.target.classList.remove('error');
                  }
                }}
                value={state.smashConfiguration.smashCompletionMessage || ''}
              >
              </textarea>
            </div>
            <div className={cx(styles.iosBlock)}>
              <ToolTip label="Show Results" text={getInfoText('results')} icon="help_outline" />
              <IosSwitch
                name="showSmashResultToParticipant"
                checked={state.smashConfiguration.showSmashResultToParticipant}
                onChange={handleCheckedChange}
                onClick={() => {}}
              />
            </div>
            <div className={cx(styles.iosBlock)}>
              <Accordion className={cx(styles.accordion)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
                  className={cx(styles.accordionSummary)}>
                  <ToolTip label="Collect Contact Data" text={getInfoText('CollectContactData')} icon="help_outline" />
                  <RadioButtonUncheckedOutlinedIcon
                    style={{ fontSize: 10, background: disable ? '#CE4AB0' : '#f0f3f4' }}
                    className={cx(styles.iosBlockCircle)} />
                </AccordionSummary>
                <AccordionDetails className={cx(styles.iosBlockDetails)}>
                  <ul>
                    <li className={cx(styles.iosBlocks)}>
                      <ToolTip label="Email Address" text={getInfoText('emailAddress')} icon="help_outline" />
                      <IosSwitch
                        name="askForParticipantEmail"
                        checked={state.smashConfiguration.askForParticipantEmail}
                        onChange={handleCheckedChange}
                        onClick={() => {}}
                      />
                    </li>
                    <li className={cx(styles.iosBlocks)}>
                      <ToolTip label="First Name" text={getInfoText('firstName')} icon="help_outline" />
                      <IosSwitch
                        name="askForParticipantFirstName"
                        checked={state.smashConfiguration.askForParticipantFirstName}
                        onChange={handleCheckedChange}
                        onClick={() => {}}
                      />
                    </li>
                    <li className={cx(styles.iosBlocks)}>
                      <ToolTip label="Last Name" text={getInfoText('lastName')} icon="help_outline" />
                      <IosSwitch
                        name="askForParticipantLastName"
                        checked={state.smashConfiguration.askForParticipantLastName}
                        onChange={handleCheckedChange}
                        onClick={() => {}}
                      />
                    </li>
                    <li className={cx(styles.iosBlocks)}>
                      <ToolTip label="Phone Number" text={getInfoText('phoneNumber')} icon="help_outline" />
                      <IosSwitch
                        name="askForParticipantPhone"
                        checked={state.smashConfiguration.askForParticipantPhone}
                        onChange={handleCheckedChange}
                        onClick={() => {}}
                      />
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={cx(styles.iosBlock)}>
              <ToolTip label="Webhook" text={getInfoText('webhook')} icon="help_outline" />
              <IosSwitch
                name="showWebhookUrl"
                checked={state.smashConfiguration.showWebhookUrl}
                onChange={handleCheckedChange}
                onClick={() => {}}
              />
            </div>
            {state.smashConfiguration.showWebhookUrl && (
              <div>
                <input
                  className="field"
                  type="text"
                  placeholder="paste webhook here"
                  id="webHookUrl"
                  name="webHookUrl"
                  onChange={handleSmashConfigurationChange}
                  value={state.smashConfiguration.webHookUrl}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      e.target.classList.add('error');
                    } else {
                      e.target.classList.remove('error');
                    }
                  }}
                />
              </div>
            )}
            <div className={cx(styles.iosBlock)}>
              <ToolTip label="Global Redirect URL" text={getInfoText('globalRedirectURL')} icon="help_outline" />
              <IosSwitch
                name="showRedirectUrl"
                checked={state.smashConfiguration.showRedirectUrl}
                onChange={handleCheckedChange}
                onClick={() => checkIfAllowed('showGlobalRedirectURL')}
                disable={getUserTierInfo(`${settings.userTier}`, 'showGlobalRedirectURL')}
              />
            </div>
            {state.smashConfiguration.showRedirectUrl && (
              <div>
                <input
                  className="field"
                  type="text"
                  placeholder="Redirect URL"
                  id="redirectUrl"
                  name="redirectUrl"
                  onChange={handleSmashConfigurationChange}
                  // eslint-disable-next-line no-undef
                  value={state.smashConfiguration.redirectUrl}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      e.target.classList.add('error');
                    } else {
                      e.target.classList.remove('error');
                    }
                  }}
                />
              </div>
            )}
            <div className={cx(styles.iosBlock, styles.applyBaselining)}>
              <ToolTip label="Apply Custom Theme" text={getInfoText('applyCustomTheme')} icon="help_outline" />
              <div className="button-edit-share">
                <Link
                  className="waves-effect waves-light"
                  onClick={preservingState}
                  to={{
                    pathname: `/CustomSmashTheme/${state.smashId}/${step}`
                  }}
                >
                  {
                    !getUserTierInfo(`${settings.userTier}`, 'applyCustomTheme')
                      && state.smashConfiguration.applyCustomTheme
                        && <i className={cx(styles.editButtonIcon, 'material-icons editButton')}>edit</i>
                  }
                </Link>
              </div>
              <IosSwitch
                name="applyCustomTheme"
                checked={state.smashConfiguration.applyCustomTheme}
                onChange={handleCheckedChange}
                onClick={() => checkIfAllowed('applyCustomTheme')}
                disable={getUserTierInfo(`${settings.userTier}`, 'applyCustomTheme')}
              />
            </div>
            <div className={styles.preview}>
              <button
                type="button"
                onClick={preserveSmashLocation}
                className={cx(
                  styles.addItemsBtn,
                  'waves-effect btn-large transparent-btn'
                )}
              >
                Preview
              </button>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className={styles.done}>
              <img src={Done} alt="img" className={styles.responsive}></img>
            </div>
            <div>
              <div className={styles.copySection}>
                <div>
                  <i
                    className={cx(
                      'material-icons generated-link-linkicon',
                      styles.shareIcon
                    )}
                  >
                    link
                  </i>
                  {user === null && (
                    <input
                      className={styles.shareInput}
                      type="text"
                      placeholder="Generated link"
                      disabled
                      readOnly={true}
                      value={formatString(`${window.location.origin}/SmashExecution/${state.smashId}
                      ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`)}
                    />
                  )}
                  {user !== null && (
                    <input
                      className={styles.shareInput}
                      type="text"
                      placeholder="Generated link"
                      readOnly={true}
                      enabled="true"
                      value={formatString(`${window.location.origin}/SmashExecution/${state.smashId}
                        ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`)}
                    />
                  )}
                </div>
                <div>
                  {user === null && (
                    <button
                      className={cx(
                        styles.cpyBtn,
                        'waves-effect waves-light btn'
                      )}
                      type="button"
                      disabled
                      onClick={() => notify(formatString(`${window.location.origin}/SmashExecution/${state.smashId}
                        ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`))}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </button>
                  )}
                  {user !== null && (
                    <button
                      className={cx(
                        styles.cpyBtn,
                        'waves-effect waves-light btn'
                      )}
                      type="button"
                      enabled="true"
                      onClick={() => notify(formatString(`${window.location.origin}/SmashExecution/${state.smashId}
                        ?tid1=${settings.googleTrackingId}&tid2=${settings.facebookTrackingId}`))}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
              </div>
              <p className={styles.doneMessage}>
                You have created a Smash! But you won't learn much if nobody
                participates. Get this link in front of people you want to
                participate by including it in an email, linking it from paid
                ads, or posting it to your social media following. The more
                participants you get, the more confidence you can have in your
                results. So get sharing!
              </p>
              <br />
              {user === null && (
                <button
                  type="button"
                  onClick={() => login()}
                  className={cx(
                    styles.signupBtn,
                    'waves-effect btn-large transparent-btn'
                  )}
                >
                  <u>SIGN-IN / SIGN-UP TO SHARE!!!</u>
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonGroup}>
          <button
            disabled={disableSteps()}
            type="button"
            className={cx(styles.next, 'waves-effect waves-light btn-large')}
            onClick={handleNext}
          >
            {getStepText()}
          </button>
          <button
            disabled={disableSteps()}
            type="button"
            className={cx(
              styles.nextIcon,
              'waves-effect waves-light btn-large'
            )}
            onClick={handleNext}
          >
            <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

Create.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Create;
