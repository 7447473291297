import Cookies from 'universal-cookie';
import AppUri from './envConfig';
import store from './store';
const cookies = new Cookies();

const domain = window.location.hostname === 'localhost'
  ? window.location.hostname
  : AppUri.DOMAIN;

const cookieKey = (key) => `${AppUri.DOMAIN || ''}${key}`;

const cookieOptions = {
  path: '/',
  domain,
};

const set = (key, value) => {
  cookies.set(cookieKey(key), value, cookieOptions);
};

const get = (key) => {
  // Bug 28008: Copy Smash button is becoming disabled in incognito window
  const data = cookies.get(cookieKey(key), cookieOptions) || null;
  if (data || key !== 'user') return data;
  const state = store.getState();
  return state?.auth?.user || null;
};

const remove = (key) => cookies.remove(cookieKey(key), cookieOptions);

export default { set, get, remove };
