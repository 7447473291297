/* eslint-disable linebreak-style */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styles from './ListItems.module.css';

function ListItems({ list = [], label = [], customTheme }) {
  function handleClick(e, x) {
    if (!x.item.itemRedirectUrl) e.preventDefault();
  }
  return (
    <div className={cx(styles.block)}>
      <div className={styles.label} style={{ color: customTheme?.smashText }}>{label}</div>
      <div className={styles.list}>
        {list.map((x, i) => (
          <a
            key={i}
            href={x.item.itemRedirectUrl || ''}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => handleClick(e, x)}
          >
            <div key={i} className={styles.item}>
              <p className={styles.name}>
                {x?.item?.imageURL && <img alt="" src={x?.item?.imageURL} className={cx(styles.icon)} />}
                {x?.item?.smashItemText === null
              || x?.item?.smashItemText?.trim() === ''
                  ? x?.item?.imageTag
                  : x?.item?.smashItemText}
              </p>
              <div className={styles.iconAndRank}>
                {x?.item?.itemRedirectUrl && <OpenInNewIcon className={cx(styles.maticon)} />}
                <span className={styles.rank}>{`#${x.rank}`}</span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

ListItems.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  customTheme: PropTypes.object.isRequired,
};

export default ListItems;
