/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const updatesmash = (smashId, payload) => connector.put(`/api/smashes/${smashId}`, payload);

const updateSmashes = {
  updatesmash
};

export default updateSmashes;
