// User Story 29695: Introduce inference into the Smash algorithm
class PairGenerator {
  rankData = {};

  populateRankData = (smashItemList) => {
    const masterDataArr = [];
    for (let i = 0; i < smashItemList.length; i++) {
      masterDataArr[i] = smashItemList[i].smashItemId; // to avoid duplicate smash pair
    }
    this.rankData = {
      masterData: smashItemList,
      masterList: masterDataArr,
      sortList: [],
      parent: [-1],
      rec: [],
      deletedItems: [],
      cmp1: 0,
      cmp2: 0,
      head1: 0,
      head2: 0,
      nrec: 0,
      numQuestion: 1,
      totalSize: 0,
      finishSize: 0,
      finishFlag: 0,
      listSource: '',
      finalListID: 0
    };
  }

  getEndOfPairDisplay = () => this.rankData.finishFlag

  initRanking = (smashItemList) => {
    this.populateRankData(smashItemList);
    let n = 0;
    let mid;
    this.rankData.sortList[n] = [];
    const masterListLength = this.rankData.masterList.length;
    for (let i = 0; i < masterListLength; i++) {
      this.rankData.sortList[n][i] = i;
    }
    n++;
    for (let i = 0; i < this.rankData.sortList.length; i++) {
      if (this.rankData.sortList[i].length >= 2) {
        mid = Math.ceil(this.rankData.sortList[i].length / 2);
        this.rankData.sortList[n] = [];
        this.rankData.sortList[n] = this.rankData.sortList[i].slice(0, mid);
        this.rankData.totalSize += this.rankData.sortList[n].length;
        this.rankData.parent[n] = i;
        n++;
        this.rankData.sortList[n] = [];
        this.rankData.sortList[n] = this.rankData.sortList[i].slice(mid, this.rankData.sortList[i].length);
        this.rankData.totalSize += this.rankData.sortList[n].length;
        this.rankData.parent[n] = i;
        n++;
      }
    }
    this.rankData.masterList.forEach(() => {
      this.rankData.rec.push(0);
    });
    this.rankData.cmp1 = this.rankData.sortList.length - 2;
    this.rankData.cmp2 = this.rankData.sortList.length - 1;
    this.showComparison();
  }

  getComparisonInfo = () => {
    if (this.rankData.cmp1 < 1) {
      return {};
    }
    const item1Ref = this.rankData.sortList[this.rankData.cmp1][this.rankData.head1];
    const item2Ref = this.rankData.sortList[this.rankData.cmp2][this.rankData.head2];
    return {
      item1: this.rankData.masterList[item1Ref],
      item2: this.rankData.masterList[item2Ref],
      item1Ref,
      item2Ref
    };
  }

  showComparison = () => {
    const { item1, item2 } = this.getComparisonInfo();
    console.log(`Items inside showComparison item1 : ${item1} , item2 : ${item2}`);
    return { item1, item2 };
  }

  handlePick = (flag) => {
    if (this.rankData.cmp1 >= 1) {
      this.updateRec(flag);
      this.sortList();
      this.rankData.numQuestion++;
    }

    this.cmpCheck();
    if (this.rankData.finishFlag !== 1) {
      // DB Call saveData(rankData)
    }
  }

  updateRec = (flag) => {
    if (flag < 0) {
      this.rankData.rec[this.rankData.nrec] = this.rankData.sortList[this.rankData.cmp1][this.rankData.head1];
      this.rankData.head1++;
      this.rankData.nrec++;
      this.rankData.finishSize++;
    } else if (flag > 0) {
      this.rankData.rec[this.rankData.nrec] = this.rankData.sortList[this.rankData.cmp2][this.rankData.head2];
      this.rankData.head2++;
      this.rankData.nrec++;
      this.rankData.finishSize++;
    }
  }

  sortList = () => {
    const cmp1Length = this.rankData.sortList[this.rankData.cmp1].length;
    const cmp2Length = this.rankData.sortList[this.rankData.cmp2].length;
    if (this.rankData.head1 < cmp1Length && this.rankData.head2 === cmp2Length) {
      while (this.rankData.head1 < cmp1Length) {
        this.updateRec(-1);
      }
    } else if (this.rankData.head1 === cmp1Length && this.rankData.head2 < cmp2Length) {
      while (this.rankData.head2 < cmp2Length) {
        this.updateRec(1);
      }
    }

    if (this.rankData.head1 === cmp1Length && this.rankData.head2 === cmp2Length) {
      for (let i = 0; i < cmp1Length + cmp2Length; i++) {
        this.rankData.sortList[this.rankData.parent[this.rankData.cmp1]][i] = this.rankData.rec[i];
      }
      this.rankData.sortList.pop();
      this.rankData.sortList.pop();
      this.rankData.cmp1 -= 2;
      this.rankData.cmp2 -= 2;
      this.rankData.head1 = 0;
      this.rankData.head2 = 0;
      const masterListLength = this.rankData.masterList.length;
      if (this.rankData.head1 === 0 && this.rankData.head2 === 0) {
        for (let i = 0; i < masterListLength; i++) {
          this.rankData.rec[i] = 0;
        }
        this.rankData.nrec = 0;
      }
    }
  }

  cmpCheck = () => {
    if (this.rankData.cmp1 < 0) {
      this.calcRankedList();
      this.rankData.finishFlag = 1;
    }
    this.showComparison();
  }

  calcRankedList = () => {
    const list = this.rankData.sortList[0];
    const rankedList = [];
    list.forEach((item, index) => {
      let obj = this.rankData.masterList[list[index]];
      obj = { ...obj, rank: index + 1 };
      rankedList.push(obj);
    });
    const rankedItems = [];
    rankedList.forEach((item) => {
      rankedItems.push({
        name: item.name
      });
    });
  }
}
// -----------------------------------------------------
export default PairGenerator;
