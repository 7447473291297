import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TagManager from 'react-gtm-module';
import Welcome from './components/createSmash/Welcome';
import Create from './components/createSmash/Create';
import ShareSmash from './components/createSmash/ShareSmash';
import Preview from './components/preview/Preview';
import SmashListing from './components/SmashListing';
import SmashDetail from './components/SmashDetail';
import GroupSessionAnalysis from './components/GroupSessionAnalysis';
import './App.css';
import ParticipantsList from './components/ParticipantsList';
import ParticipantDetail from './components/ParticipantDetail';
import store from './store';
import userManager, { loadUserFromStorage } from './userService';
import AuthProvider from './utils/authProvider';
import ProtectedRoute from './utils/protectedRoute';
import GroupSessionList from './components/GroupSessionList';
import CustomSmashTheme from './components/customSmashTheme/CustomSmashTheme';

toast.configure();

function App() {
  const setGlobalUserProps = (user) => {
    const { auth } = store.getState();
    const rs = {};
    rs.id = auth?.user?.profile?.sub || user?.profile?.sub;
    rs.kid = auth?.user?.profile?.kid || user?.profile?.kid;
    rs.email = auth?.user?.profile?.email || user?.profile?.email;
    rs.fname = auth?.user?.profile?.fname || user?.profile?.fname;
    window.rs = rs;
    document.getElementById('rsGlobalUserProperties').value = JSON.stringify(rs);
  };

  const appHeight = () => {
    const bHeight = window.innerHeight; // document.body.scrollHeight;
    document.querySelector('.wrapper-body').style.height = `${bHeight}px`;
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WD5D6LK'
    };
    TagManager.initialize(tagManagerArgs);
    // fetch current user from cookies
    loadUserFromStorage(store);
    appHeight();
  }, []);

  return (
    <div className="wrapper-body">
      <header className="App-header">
        <Provider store={store}>
          <AuthProvider userManager={userManager} store={store} setGlobalUserProps={setGlobalUserProps}>
            <Router>
              <Switch>
                <Route
                  path="/Welcome"
                  render={(props) => (
                    <Welcome {...props} setGlobalUserProps={setGlobalUserProps} />
                  )}
                />
                <Route exact path="/">
                  <Redirect to="/Welcome" />
                </Route>
                <Route exact path="/create" component={Create} />
                <Route exact path="/CreateSmashConfiguration/:smashId/:step" component={Create} />
                <Route exact path="/SmashExecution/:smashId/:preview?" component={Preview} />
                <ProtectedRoute exact path="/SmashListing" component={SmashListing} />
                <ProtectedRoute exact path="/CustomSmashTheme/:smashId/:step" component={CustomSmashTheme} />
                <ProtectedRoute exact path="/CustomSmashTheme" component={CustomSmashTheme} />
                <ProtectedRoute exact path="/CustomSmashTheme/preview" component={Preview} />
                <ProtectedRoute exact path="/SmashDetail" component={SmashDetail} />
                <ProtectedRoute exact path="/ParticipantsList" component={ParticipantsList} />
                <ProtectedRoute exact path="/ParticipantDetail" component={ParticipantDetail} />
                <ProtectedRoute exact path="/GroupSessionAnalysis" component={GroupSessionAnalysis} />
                <ProtectedRoute exact path="/GroupSessionList" component={GroupSessionList} />
                <Route
                  exact
                  path="/ShareSmash"
                  render={(props) => (
                    <ShareSmash {...props} setGlobalUserProps={setGlobalUserProps} />
                  )} />
              </Switch>
            </Router>
          </AuthProvider>
        </Provider>
      </header>
    </div>
  );
}

export default App;
