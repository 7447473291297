import React from 'react';
import cx from 'classnames';
import { getInfoText } from 'components/createSmash/Utils/Utils';
import ToolTip from 'components/shared/ToolTip';
import PropTypes from 'prop-types';
import createSmash from '../createSmash/Create.module.css';
import customSmash from '../customSmashTheme/CustomSmashTheme.module.css';

function InputList(props) {
  const {
    label, id, name, value, placeholder, maxLength, handleChange, error: errorText
  } = props;
  return (
    <div className={cx(createSmash.block, 'input-field', customSmash.lists)}>
      <div className={cx(customSmash.inputContainer)}>
        <ToolTip
          label={label}
          text={getInfoText(name)}
          icon="help_outline"
          className={cx(customSmash.label)} />
        <input
          className={cx(createSmash.field, customSmash.inputs, errorText && 'error')}
          type="text"
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          maxLength={maxLength}
        />
      </div>
      { errorText && <p className={cx(customSmash.errorText)}>{errorText}</p> }
    </div>
  );
}

InputList.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  error: PropTypes.string
};

InputList.defaultProps = {
  error: ''
};

export default InputList;
