import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import createSmash from '../createSmash/Create.module.css';
import customSmash from './CustomSmashTheme.module.css';
import InputList from '../shared/InputList';

const CustomSmashThemeBody = React.forwardRef((props) => (
  /* {isLoading ? (
      this.showLoader()
    ) : ( */
  <div className={cx(createSmash.body, customSmash.body)}>
    {
      /* {isLoading ? (
        <div className="backdroploader">
          <div className="loader"></div>
        </div>
        ) : ''} */
    }
    {/* <form ref={ref}> */}
    <InputList
      label="Background"
      id="background"
      name="background"
      placeholder="# Default Gradient"
      value={props.formValues.background}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.background}
    />
    <InputList
      label="Card Color"
      id="cardColor"
      name="cardColor"
      placeholder="#FFFFFF"
      value={props.formValues.cardColor}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.cardColor}
    />
    <InputList
      label="Smash Text"
      id="smashText"
      name="smashText"
      placeholder="#FFFFFF"
      value={props.formValues.smashText}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.smashText}
    />
    <InputList
      label="Card Text"
      id="cardText"
      name="cardText"
      placeholder="#212430"
      value={props.formValues.cardText}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.cardText}
    />
    <InputList
      label="Button"
      id="button"
      name="button"
      placeholder="#0047ab"
      value={props.formValues.button}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.button}
    />
    <InputList
      label="Button Split"
      id="buttonSplit"
      name="buttonSplit"
      placeholder="#2f83f9"
      value={props.formValues.buttonSplit}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.buttonSplit}
    />
    <InputList
      label="Button Text"
      id="buttonText"
      name="buttonText"
      placeholder="#FFFFFF"
      value={props.formValues.buttonText}
      maxLength="20"
      handleChange={props.handleChange}
      error={props.formErrors?.buttonText}
    />
    {/* </form> */}
    <div className={createSmash.preview}>
      <button
        type="button"
        onClick={props.previewSmash}
        className={cx(createSmash.addItemsBtn, 'waves-effect btn-large transparent-btn')}>
        Preview
      </button>
    </div>
  </div>
  /* )} */
));

CustomSmashThemeBody.propTypes = {
  formValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  previewSmash: PropTypes.func.isRequired,
};

export default CustomSmashThemeBody;
