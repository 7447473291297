/* eslint-disable react/prop-types */
// eslint-disable linebreak-style //
import React, { useState, createRef, useEffect } from 'react';
import cx from 'classnames';
import Api from 'api';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ToolTip from 'components/shared/ToolTip';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import { getInfoText } from 'components/createSmash/Utils/Utils';
import { ValidateImage } from '../../utils/imageUtils';
import { errorToast } from '../../utils/Toast';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AddItems.module.css';

function AddItems({
  selectedItem, smashId, onAdd, onBack, checkIfAllowed
}) {
  const [itemDescription, setItemDescription] = useState('');
  const [itemTag, setItemTag] = useState('');
  const [itemRedirectURL, setItemRedirectURL] = useState('');
  const [SmashItemImage, setSmashItemImage] = useState({});
  const [smashItemId, setSmashItemId] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imageDisplayName, setImageDisplayName] = useState('');
  const inputRef = createRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setItemDescription(selectedItem.smashItemText);
      setItemTag(selectedItem.imageTag);
      setSmashItemId(selectedItem.smashItemId);
      setImageURL(selectedItem.imageURL);
      setImageDisplayName(selectedItem.imageDisplayName);
      setItemRedirectURL(selectedItem.itemRedirectUrl);
    }
  }, []);

  const addItem = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('SmashItemImage', SmashItemImage);
      formData.append('ImageTag', itemTag);
      // Bug 40529: Null is getting saved, if the smash item description is empty.
      // formData.append('SmashText', itemDescription);
      formData.append('SmashItemId', smashItemId);
      formData.append('ImageURL', imageURL);
      formData.append('ImageDisplayName', imageDisplayName);
      formData.append('ItemRedirectURL', itemRedirectURL);
      // Bug 40529: Null is getting saved, if the smash item description is empty.
      if (itemDescription == null) {
        formData.append('SmashText', '');
      } else {
        formData.append('SmashText', itemDescription);
      }
      const { data: { smashItems } } = await Api.createOption(formData, smashId);
      if (selectedItem?.smashItemId) {
        const item = smashItems.filter(x => x.smashItemId === selectedItem.smashItemId);
        onAdd(item[0]);
      } else {
        onAdd(smashItems[smashItems.length - 1]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 412) {
        errorToast('Duplicate item found');
      }
    }
  };

  // eslint-disable-next-line react/no-multi-comp
  const showLoader = () => (<div className="smash-body"><div className="loader"></div></div>);

  return (
    <div className={styles.block}>
      <div className={styles.header}>
        <h6 className={styles.h6}>
          <span className={styles.icon}>
            <button type="button" className={styles.backBtn} onClick={() => onBack()}>
              <i className={cx(styles.backIcon, 'fa fa-angle-left')} aria-hidden="true"></i>
            </button>
            Add Items
          </span>
        </h6>
      </div>
      {isLoading ? showLoader()
        : (
          <div className={styles.body}>
            <div
              className={cx(styles.block, 'input-field')}
              style={{ borderBottom: '1px solid #D8D8D8' }}
            >
              <ToolTip label="Item Name" text={getInfoText('imageTag')} icon="help_outline" />
              <input
                className={styles.field}
                type="text"
                placeholder="Name"
                id="name"
                maxLength="40"
                onChange={(e) => setItemTag(e.target.value)}
                value={itemTag}
                onBlur={(e) => {
                  if (e.target.value?.trim().length === 0) {
                    e.target.classList.add('error');
                  } else {
                    e.target.classList.remove('error');
                  }
                }}
              />
            </div>
            <div className={cx(styles.block, 'input-field')}>
              <ToolTip label="Item Description (Optional)" text={getInfoText('imageDesc')} icon="help_outline" />
              <textarea
                rows={3}
                className={cx('materialize-textarea', styles.textarea)}
                placeholder="Description"
                id="desc"
                maxLength="140"
                onChange={(e) => setItemDescription(e.target.value)}
                value={itemDescription}
              />
            </div>

            <div
              className={cx(styles.block, 'input-field')}
              style={{ borderBottom: '1px solid #D8D8D8' }}
            >
              <ToolTip
                label="Add Image"
                text={getInfoText('addImage')}
                icon="help_outline"
                additionalIcon={(
                  <AddPhotoAlternateOutlinedIcon
                    style={{ fontSize: 25 }}
                    onClick={() => inputRef.current.click()}
                  />
                )}
              />
              <div
                role="presentation"
                className={styles.iconSection}
                onClick={() => inputRef.current.click()}
              >
                <input
                  accept=".jpeg,.jpg,.png"
                  ref={inputRef}
                  className={styles.hide}
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (ValidateImage(file)) {
                      setSmashItemImage(file);
                    } else {
                      errorToast('Please upload valid image file !!');
                    }
                  }}
                />
              </div>
              {(SmashItemImage?.name?.length > 0 || selectedItem?.imageDisplayName) && (
                <div className={styles.added}>
                  {`Added - ${SmashItemImage?.name || selectedItem?.imageDisplayName}`}
                </div>
              )}
            </div>
            <div className={cx(styles.block, 'input-field')}>
              <ToolTip label="Redirect URL" text={getInfoText('redirect')} icon="help_outline" />
              <input
                className={styles.field}
                type="text"
                placeholder="Redirect URL"
                id="itemRedirectURL"
                maxLength="255"
                onFocus={() => checkIfAllowed('itemRedirectURL')}
                onChange={(e) => setItemRedirectURL(e.target.value)}
                value={itemRedirectURL}
              />
            </div>
          </div>
        )}
      <div className={styles.footer}>
        <div className={styles.buttonGroup}>
          <button
            disabled={itemTag?.trim().length === 0 || (isEmpty(SmashItemImage?.name)
              && itemDescription?.trim().length === 0)}
            type="button"
            onClick={addItem}
            className={cx(styles.next, 'waves-effect waves-light btn-large')}
          >
            Add Item
          </button>
        </div>
      </div>
    </div>
  );
}

AddItems.propTypes = {
  smashId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default AddItems;
