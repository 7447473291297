import smash from './createSmash';
import smashes from './smashListing';
import smashInsights from './smashDetail';
import participants from './participantsList';
import participantdetail from './participantdetail';
import updateSmashes from './updateSmash';
import associateSmashes from './shareSmash';
import usersettingApis from './trackingSettings';
import groupSessionAnalysis from './groupSessionAnalysis';
import groupSession from './groupSession';

const Api = {
  ...smash,
  ...smashes,
  ...smashInsights,
  ...participants,
  ...participantdetail,
  ...updateSmashes,
  ...associateSmashes,
  ...usersettingApis,
  ...groupSessionAnalysis,
  ...groupSession
};

export default Api;
