/* eslint-disable linebreak-style */
import { api as connector } from './axios';

const getSmashes = () => connector.get('/API/Smashes');

const smashes = {
  getSmashes
};

export default smashes;
