import { UserTier } from '../../../Constants/Constant';

export const getShowGlobalRedirectUrlPermission = (userTierPermission) => {
  // Bug 29404: "Create your own" not creating new account creation flow
  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(userTierPermission)
    || userTierPermission === undefined
    || userTierPermission === UserTier.Free
  ) {
    return true;
  }
  return false;
};

export const getHideSmashIntroPermission = (userTierPermission) => {
  // Bug 29404: "Create your own" not creating new account creation flow
  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(userTierPermission)
    || userTierPermission === undefined
    || userTierPermission === UserTier.Free
    || userTierPermission === UserTier.Silver
  ) {
    return true;
  }
  return false;
};

export const getApplyCustomThemePermission = (userTierPermission) => {
  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(userTierPermission)
    || userTierPermission === undefined
    || userTierPermission === UserTier.Free
  ) {
    return true;
  }
  return false;
};

export const getItemRedirectURLPermission = (userTierPermission) => {
  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(userTierPermission)
    || userTierPermission === undefined
    || userTierPermission === UserTier.Free
  ) {
    return true;
  }
  return false;
};

export const getCustomizeSmashIntroTextPermission = (userTierPermission) => {
  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(userTierPermission)
    || userTierPermission === undefined
    || userTierPermission === UserTier.Free
  ) {
    return true;
  }
  return false;
};

export const getUserTierInfo = (userTierPermission, feature) => {
  // eslint-disable-next-line radix
  const tier = parseInt(`${userTierPermission}`);
  switch (feature) {
    case 'hideSmashIntro': return getHideSmashIntroPermission(tier);
    case 'showGlobalRedirectURL': return getShowGlobalRedirectUrlPermission(tier);
    case 'applyCustomTheme': return getApplyCustomThemePermission(tier);
    case 'itemRedirectURL': return getItemRedirectURLPermission(tier);
    case 'customizeSmashIntroText': return getCustomizeSmashIntroTextPermission(tier);
    default: return false;
  }
};
