/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import Api from 'api';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SwipeButton from 'react-swipezor';
import { take, sortBy } from 'lodash';
import './SmashDetail.css';
import ConfirmationBox2 from './shared/ConfirmationBox2';
import { SmashStatus } from '../Constants/Constant';

class SmashDetail extends Component {
  constructor(props) {
    super(props);
    const { location: { state: { data } } } = this.props;
    this.state = {
      smashInsight: [],
      smashStatus: this.capitalize(data.status),
      showMore: false,
      isLoading: true,
      openConfirmBox: false,
      smashTargetStatus: null,
      reset: 0,
    };
  }

  componentDidMount() {
    const { location: { state: { data } } } = this.props;
    Api.getSmashInsights(data.smashId)
      .then(res => {
        this.setState({ smashInsight: res.data, isLoading: false });
      });
  }

  // User Story 13322: Confirmation message on de-activating a smash
  handleClose = async (value) => {
    console.log(value, 'dialog closed');
    if (!value) {
      this.setState((prevState) => ({
        ...prevState,
        openConfirmBox: false,
        reset: prevState.reset + 1,
      }));
      return;
    }
    const { location: { state: { data } } } = this.props;
    const { smashTargetStatus } = this.state;
    await Api.updatesmash(data.smashId, {
      ...data,
      status: smashTargetStatus, // SmashStatus.Complete, Inactive
      endDateTime: new Date().toISOString()
    });
    this.setState({ smashStatus: 'Complete', openConfirmBox: false });
  };

  onSwipeDone = (smashTargetStatus) => {
    this.setState((prevState) => ({
      ...prevState,
      openConfirmBox: true,
      smashTargetStatus
    }));
  };

  capitalize(status) {
    if (!status) return status;
    return status[0].toUpperCase() + status.substring(1);
  }

  render() {
    const {
      smashInsight, smashStatus, showMore, isLoading, openConfirmBox, reset
    } = this.state;
    const { location: { state: { data } } } = this.props;
    const rankedPercentage = smashInsight?.rankedItemPercentage || 0;
    const avgDecisionDuration = smashInsight?.avgDecisionDuration || 0;
    const avgSessionDuration = smashInsight?.avgSessionDuration || 0;
    return (
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* <!-- smash-block starts --> */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh1">
                  <span className="header-icon">
                    <Link to="/SmashListing"><i className="fa fa-angle-left" aria-hidden="true"></i></Link>
                    {smashInsight.smashName}
                  </span>
                  <span className="header-breadcrumb" style={{ textDecoration: 'underline' }}>
                    <Link
                      to={`/SmashExecution/${data?.smashId}/preview`}
                    >
                      View Smash
                    </Link>
                  </span>
                </h6>
              </div>
              <div className="smash-body">
                {isLoading ? (
                  <div className="backdroploader">
                    <div className="loader"></div>
                  </div>
                ) : '' }
                <div className="smash-lists-block smash-detail-block">
                  <h5>Summary</h5>
                  <span className="smash-state-span">
                    {smashStatus}
                  </span>
                </div>
                <div className="smash-detail-div">
                  <div className="smash-detail-participants-list">
                    <p className="participants-no-para">
                      <span>
                        {smashInsight.participantCount || 0}
                      </span>
                      Participants
                    </p>
                    <Link
                      to={{ pathname: '/ParticipantsList', state: { data: smashInsight } }}
                      onClick={(e) => { if (!smashInsight.participantCount) { e.preventDefault(); } }}>
                      <span
                        // eslint-disable-next-line max-len
                        className={`participants-view-all-span${smashInsight.participantCount ? '' : ' disableButton'}`}>
                        View All
                      </span>
                    </Link>
                  </div>
                  <div className="smash-detail-items">
                    <div className="ranked-items">
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">{`${rankedPercentage}%`}</h4>
                        <p className="ranked-items p">Items ranked </p>
                      </div>
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">{`${avgSessionDuration}`}</h4>
                        <p className="ranked-items p">Avg Session</p>
                      </div>
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">{`${avgDecisionDuration}`}</h4>
                        <p className="ranked-items p">Avg Decision</p>
                      </div>
                    </div>
                  </div>
                  {smashStatus?.toLowerCase() === 'active'
                    ? (
                      <SwipeButton
                        classList="swipe-smash-button-background"
                        caretClassList="swipe-smash-caret"
                        overlayClassList="swipe-smash-caret"
                        caret={(
                          <div className="swipe-smash-slider">
                            <i className="material-icons">arrow_forward</i>
                          </div>
                        )}
                        mainText="Swipe to end smash"
                        onSwipeDone={() => {
                          this.onSwipeDone(SmashStatus.Complete);
                        }}
                        reset={reset}
                      />
                    )
                    : (
                      <SwipeButton
                        classList="swipe-smash-button-background"
                        caretClassList="swipe-smash-caret"
                        overlayClassList="swipe-smash-caret-container"
                        caret={(
                          <div className="swipe-smash-slider">
                            <i className="material-icons">arrow_forward</i>
                          </div>
                        )}
                        mainText="Swipe to deactivate smash"
                        onSwipeDone={() => {
                          this.onSwipeDone(SmashStatus.Inactive);
                        }}
                        reset={reset}
                      />
                    )}
                </div>
                <div className="smash-lists-block smash-detail-block">
                  {/* User Story 26900: Top results says "top 5 results" even if there are only 4 items */}
                  <h5>
                    {smashInsight?.rankedItems?.length < 5
                      ? `Top ${smashInsight?.rankedItems?.length} Ranked Items`
                      : 'Top 5 Ranked Items'}
                  </h5>
                  <span
                    role="presentation"
                    className={`participants-view-all-span${smashInsight?.rankedItems?.length ? '' : ' disableButton'}`}
                    onClick={(e) => {
                      if (!smashInsight?.rankedItems?.length) { e.preventDefault(); return; }
                      this.setState({ showMore: !showMore });
                    }}
                  >
                    {!showMore ? `View All ${smashInsight?.rankedItems?.length || 0} Items` : 'View Less Items' }
                  </span>
                </div>
                <div className="smash-items-list">
                  {take(sortBy(smashInsight?.rankedItems, o => o.rank),
                    showMore ? smashInsight?.rankedItems?.length : 5)?.map(
                    (item, index) => (
                      <div key={index}>
                        <div
                          aria-hidden="true"
                          className="smash-items-block"
                          onClick={() => {
                            smashInsight.rankedItems.forEach(element => {
                              element.IsActive = false;
                            });
                            this.setState({
                              ...smashInsight.rankedItems[index].IsActive = !item.IsActive
                            });
                          }}>
                          <div className="smash-items-block-item">
                            <p className="smash-items-block-item-name">
                              {item?.item?.imageURL ? (
                                <img className="img" width="36px" height="36px" src={item.item.imageURL} alt="" />
                              ) : (
                                <i className="material-icons">image</i>
                              )}
                              {item.item.imageTag}
                            </p>
                          </div>
                          <span
                            className="smash-items-block-item-number">
                            #
                            {item.rank}
                          </span>
                        </div>
                        { item.IsActive === true
                          && (
                            <div
                              className="smash-items-block-expand">
                              <div className="smash-items-block-details">
                                <span className="span.smash-items-block-item-detail-number">
                                  Display Count
                                  <br></br>
                                  {/* User Story 32844: Distinguish between Smash pair
                                   created by user vs system - Display Count */}
                                  {/* Bug 33639: Build failure fixes */}
                                  <span className="span.smash-items-block-item-detail-number">
                                    {item.displayCountExcludingSystemPairs}
                                  </span>
                                </span>
                              </div>
                              <div className="smash-items-block-details">
                                <span className="span.smash-items-block-item-detail-number">
                                  {' '}
                                  Win%
                                  <br></br>
                                  <span className="span.smash-items-block-item-detail-number">{item.winPercentge}</span>
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {/* <!-- smash-block ends --> */}
            <ConfirmationBox2
              isOpen={openConfirmBox}
              onClose={this.handleClose}
              objectNameToDelete="Smash"
            />
          </div>
        </div>
      </div>
    );
  }
}

SmashDetail.propTypes = {
  location: PropTypes.object.isRequired
};

export default SmashDetail;
