/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './ParticipantsList.css';
import Api from 'api';

class ParticipantsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location: { state: { data } } } = this.props;
    if (data && data.smashId) {
      Api.getParticipants(data.smashId)
        .then(res => {
          this.setState({ participants: res.data, isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { participants, isLoading } = this.state;
    const { history } = this.props;
    return (
    // <!-- wrapper starts -->
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* <!-- smash-block starts --> */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh6">
                  <button type="button" className="backBtn" onClick={() => history.goBack()}>
                    <i className="backIcon fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <span className="header-icon">
                    Participants
                  </span>
                </h6>
              </div>
              <div className="smash-body">
                {isLoading ? (
                  <div className="backdroploader">
                    <div className="loader"></div>
                  </div>
                ) : '' }
                <div className="smash-lists-block smash-detail-block">
                  <h5>All Participants</h5>
                </div>
                {
                  // Task 38938: Build Failed Issue
                  participants.map((participant, index) => (
                    <div className="smash-participants-lists-div" key={`${participant.smashId}${index}`}>
                      <Link
                        to={participant.totalSessionCount > 1
                          ? { pathname: '/GroupSessionAnalysis', state: { data: participant } }
                          : { pathname: '/ParticipantDetail', state: { data: participant } }}>
                        <div className="participants-list-block">
                          <div className="participant-list-img">
                            <i className="fa fa-user-o" aria-hidden="true"></i>
                          </div>
                          <div className="participant-list-det">
                            <p>{moment(participant.participationDate).format('DD-MMM-YYYY')}</p>
                            <h5>{participant.participantEmail}</h5>
                          </div>
                          {/* User Story 33713: Aggregate the duplicate sessions- All Participants Change */}
                          {participant.totalSessionCount > 1 ? (
                            <p className="participant-session">
                              <span>{participant.totalSessionCount}</span>
                              Sessions
                            </p>
                          ) : '' }
                        </div>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
            {/* <!-- smash-block ends --> */}
          </div>
        </div>
      </div>
    // <!-- wrapper ends -->
    );
  }
}

ParticipantsList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ParticipantsList);
