// eslint-disable react/jsx-props-no-spreading //
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookieService from '../cookieService';
function ProtectedRoute({ exact, path, component: Component }) {
  const user = cookieService.get('user');

  return (user !== null)
    ? (<Route exact={exact} path={path} component={Component} />)
    : (<Redirect to="/Welcome" />);
}

ProtectedRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired
};

export default ProtectedRoute;
