/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { TextField, withStyles } from '@material-ui/core';

const styles = {
  phoneContainer: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 16px #455b6314 !important',
    borderRadius: 'var(--xs-spacing) !important',
    color: 'black',
    marginTop: '30px !important',
    marginBottom: '8px !important',
  },
  field: {
    marginLeft: '41px ! important',
    borderBottom: 'none ! important',
    width: '-webkit-fill-available !important',
    position: 'relative',
    left: '10px',
    marginRight: '12px !important',
    flagDropdown: {
      borderRadius: '8px 0 0 8px ! important',
    }
  },
  countryList: {
    display: 'flex ! important',
    maxHeight: '150px ! important',
    flexDirection: 'column ! important',
    alignContent: 'flex-start ! important',
    flexWrap: 'nowrap ! important',
    justifyContent: 'center ! important',
    alignItems: 'flex-start ! important',
    '@media (max-width: 350px)': {
      width: '250px ! important'
    }
  },
};

function PhoneNumber(props) {
  const {
    classes, id, onChange, value
  } = props;
  const onPhoneNumberChange = (e) => {
    onChange(e);
  };

  return (
    <div
      className={classes.phoneContainer}>
      <PhoneInput
        inputProps={{
          name: id,
          required: true,
        }}
        value={value}
        onChange={(e) => onPhoneNumberChange(e)}
        inputClass={classes.field}
        dropdownClass={classes.countryList}
        component={TextField}
      />
    </div>
  );
}

PhoneNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
  onChange: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired
};

export default withStyles(styles)(PhoneNumber);
