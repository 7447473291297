import React, { Component } from 'react';
import Api from 'api';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { take, sortBy } from 'lodash';
import './SmashDetail.css';

class GroupSessionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantSmashInsight: [],
      showMore: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location: { state: { data } } } = this.props;
    // Bug 34132: Session Group Summary page is not loading when user entered +1 email id
    const encodedString = Buffer.from(data.participantEmail).toString('base64');
    if (encodedString) {
      Api.getGroupSessionAnalysis(data.smashId, encodedString)
        .then(res => {
          this.setState({ participantSmashInsight: res.data, isLoading: false });
        });
    }
  }

  render() {
    const {
      participantSmashInsight,
      showMore,
      isLoading
    } = this.state;
    const { history } = this.props;
    const rankedPercentage = participantSmashInsight?.rankedItemPercentage || 0;
    const avgDecisionDuration = participantSmashInsight?.avgDecisionDuration || 0;
    const avgSessionDuration = participantSmashInsight?.avgSessionDuration || 0;
    return (
      <div className="wrapper">
        <div className="wrapper-body">
          <div className="main-screen">
            {/* <!-- smash-block starts --> */}
            <div className="smash-block">
              <div className="smash-header">
                <h6 className="smash-headerh1">
                  <span className="header-icon">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>
                      <i className="backIcon fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                    {participantSmashInsight.participantEmail}
                  </span>
                </h6>
              </div>
              <div className="smash-body">
                {isLoading ? (
                  <div className="backdroploader">
                    <div className="loader"></div>
                  </div>
                ) : (
                  ''
                )}
                <div className="smash-lists-block smash-detail-block">
                  <h5>Session Group Summary</h5>
                </div>
                <div className="smash-detail-div">
                  <div className="smash-detail-participants-list">
                    <p className="participants-no-para">
                      <span>
                        {participantSmashInsight.sessionCount}
                      </span>
                      Sessions
                    </p>
                    <Link
                      to={{
                        pathname: '/GroupSessionList',
                        state: { data: participantSmashInsight },
                      }}
                    >
                      <span className="participants-view-all-span">
                        View All
                      </span>
                    </Link>
                  </div>
                  <div className="smash-detail-items">
                    <div className="ranked-items">
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">
                          {`${rankedPercentage}%`}
                        </h4>
                        <p className="ranked-items p">
                          Items ranked
                        </p>
                      </div>
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">
                          {`${avgSessionDuration}`}
                        </h4>
                        <p className="ranked-items p">Avg Session</p>
                      </div>
                      <div className="ranked-item-details">
                        <h4 className="ranked-items h4">
                          {`${avgDecisionDuration}`}
                        </h4>
                        <p className="ranked-items p">Avg Decision</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="smash-lists-block smash-detail-block">
                  <h5>
                    {participantSmashInsight?.rankedItems?.length < 5
                      ? `Top ${participantSmashInsight?.rankedItems?.length} Ranked Items`
                      : 'Top 5 Ranked Items'}
                  </h5>
                  <span
                    role="presentation"
                    className={`participants-view-all-span${
                      participantSmashInsight?.rankedItems?.length ? '' : ' disableButton'
                    }`}
                    onClick={(e) => {
                      if (!participantSmashInsight?.rankedItems?.length) {
                        e.preventDefault();
                        return;
                      }
                      this.setState({ showMore: !showMore });
                    }}
                  >
                    {!showMore
                      ? `View All ${participantSmashInsight?.rankedItems?.length
                          || 0} Items`
                      : 'View Less Items'}
                  </span>
                </div>
                <div className="smash-items-list">
                  {take(sortBy(participantSmashInsight?.rankedItems, o => o.rank),
                    showMore ? participantSmashInsight?.rankedItems?.length : 5)?.map(
                    (item, index) => (
                      <div key={index}>
                        <div
                          aria-hidden="true"
                          className="smash-items-block"
                          onClick={() => {
                            participantSmashInsight.rankedItems.forEach(element => {
                              element.IsActive = false;
                            });
                            this.setState({
                              ...participantSmashInsight.rankedItems[index].IsActive = !item.IsActive
                            });
                          }}>
                          <div className="smash-items-block-item">
                            <p className="smash-items-block-item-name">
                              {item?.item?.imageURL ? (
                                <img className="img" width="36px" height="36px" src={item.item.imageURL} alt="" />
                              ) : (
                                <i className="material-icons">image</i>
                              )}
                              {item.item.imageTag}
                            </p>
                          </div>
                          <span
                            className="smash-items-block-item-number">
                            #
                            {item.rank}
                          </span>
                        </div>
                        { item.IsActive === true
                          && (
                            <div
                              className="smash-items-block-expand">
                              <div className="smash-items-block-details">
                                <span className="span.smash-items-block-item-detail-number">
                                  Display Count
                                  <br></br>
                                  <span className="span.smash-items-block-item-detail-number">
                                    {/* Bug 34163: Display Count of item is coming wrong
                                    on Session Group summary screen */}
                                    {item.displayCountExcludingSystemPairs}
                                  </span>
                                </span>
                              </div>
                              <div className="smash-items-block-details">
                                <span className="span.smash-items-block-item-detail-number">
                                  Win%
                                  <br></br>
                                  <span className="span.smash-items-block-item-detail-number">{item.winPercentge}</span>
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- smash-block ends --> */}
        </div>
      </div>
    );
  }
}

GroupSessionAnalysis.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default GroupSessionAnalysis;
