/* eslint-disable linebreak-style */
import axios from 'axios';
import AppUri from '../envConfig';
import store from '../store';
import { userSignedOut } from '../actions/authActions';
import cookieService from '../cookieService';

const api = axios.create({
  baseURL: AppUri.APP_RANKSMASH_API_BASE_URI,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
});
api.interceptors.request.use(
  (config) => {
    const user = cookieService.get('user');
    const accessToken = user?.access_token;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(userSignedOut());
      (window.location.pathname = '/Welcome');
    }
    return Promise.reject(error);
  }
);

export { api };
