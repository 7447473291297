/* eslint-disable linebreak-style */
import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from './actions/authActions';
import AppUri from './envConfig';
import cookieService from './cookieService';
const config = {
  authority: AppUri.APP_AUTH_SERVICE_BASE,
  client_id: 'RankSmashApp',
  redirect_uri: AppUri.APP_AUTH_REDIRECT_URI,
  response_type: 'id_token token',
  scope: 'RankSmash.Service openid profile',
  post_logout_redirect_uri: AppUri.APP_AUTH_LOGOUT_REDIRECT_URI
};
const config1 = {
  authority: AppUri.APP_AUTH_SERVICE_BASE,
  client_id: 'RankSmashApp',
  redirect_uri: AppUri.APP_WELCOME_AUTH_REDIRECT_URI,
  response_type: 'id_token token',
  scope: 'RankSmash.Service openid profile',
  post_logout_redirect_uri: AppUri.APP_AUTH_LOGOUT_REDIRECT_URI
};

const userManager = new UserManager(config);

export async function loadUserFromStorage(store) {
  try {
    const user = cookieService.get('user');
    // console.log(`user in storage${user}`);
    if (!user) { return store.dispatch(storeUserError()); }
    store.dispatch(storeUser(user));
  } catch (e) {
    console.error(`User not found: ${e}`);
    store.dispatch(storeUserError());
  }
}

export function signinRedirect() {
  console.log(config);
  return userManager.signinRedirect();
}

export function signinRedirectWelcome() {
  console.log(config1);
  const userManager = new UserManager(config1);
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signInUser(store) {
  return userManager.signinRedirectCallback().then(
    (user) => {
      if (!user) { return store.dispatch(storeUserError()); }
      console.log(`user in signinUser${user}`);
      cookieService.set('user', JSON.stringify(user));
      store.dispatch(storeUser(user));
    }
  ).catch((error) => { console.error(error); });
}

export async function signoutRedirect() {
  const user = cookieService.get('user');
  const signoutUrl = await userManager.createSignoutRequest({ id_token_hint: user.id_token });
  window.location.href = signoutUrl.url;
  userManager.clearStaleState();
  userManager.removeUser();
  cookieService.remove('user');
  // return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export default userManager;
