/* eslint-disable keyword-spacing */
/* eslint-disable linebreak-style */
// User Story 29695: Introduce inference into the Smash algorithm
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Api from 'api';
import { isEmpty } from 'lodash';
import cx from 'classnames';
// import { useSelector } from 'react-redux';
// import store from '../../store';
import { getTicks } from './utils/Utils';
import styles from './SmashPairs.module.css';
import PairGenerator from './utils/PairGenerator';

let pairGenerator = null;
function SmashPairs({
  callback,
  setSelectedPairs,
  isPreview,
  startMessage,
  smashItemList,
  smashId,
  sessionId,
  customTheme
}) {
  if(pairGenerator === null) {
    pairGenerator = new PairGenerator();
  }

  // const customTheme = useSelector(state => state.customTheme);
  // const a = useSelector(state => state.auth);
  const [currentSmashPair, setCurrentSmashPair] = useState(null);
  const [displayPairCount, setDisplayPairCount] = useState(1);

  const boxRef = useRef();
  const [initialTick, setInitialTick] = useState();
  const updateOption = async (item, selected, isLast) => {
    if (!isPreview) {
      const { smashId, sessionId } = item;
      const winTime = (getTicks() - initialTick).toString();
      const payload = {
        ...item,
        winnerItem: item[selected],
        winnerDecisionTime: winTime,
        item1DisplayOrder: 1,
        item2DisplayOrder: 2,
        // User Story 32664: Distinguish between Smash pair created by user vs system - Database
        generatedFrom: 0,
        // Task 39114: Add PresentedDateTime in all the RankSmashDB.SmashPairs documents
        presentedDateTime: new Date()
      };
      await Api.updateSmashPair(smashId, sessionId, payload);
    }

    setInitialTick(getTicks());
    if (isLast) {
      // Bug 33444: Presented smash pair count is coming less than actual presented count pair
      callback(displayPairCount);
    }
  };
  const getSmashPair = () => {
    const itemPair = pairGenerator.showComparison();
    const smashPairObject = {
      smashPairId: '',
      sessionId,
      smashId,
      item1: { },
      item2: { },
      winnerItem: {},
      winnerDecisionTime: '',
      item1DisplayOrder: 1,
      item2DisplayOrder: 2
    };
    for (let i = 0; i < smashItemList.length; i++) {
      if (smashItemList[i].smashItemId === itemPair.item1) {
        smashPairObject.item1 = smashItemList[i];
      }
      // to avoid duplicate smash pair
      if (smashItemList[i].smashItemId === itemPair.item2) {
        smashPairObject.item2 = smashItemList[i];
      }
    }
    setCurrentSmashPair(smashPairObject);
  };

  const toggleNext = (item, selected) => {
    if (!boxRef || !boxRef.current) {
      return;
    }
    // Issue 32599: Lack of animation
    const { width } = boxRef.current.getBoundingClientRect();
    if(width != null) {
      boxRef.current.style.transform = `translateX(-${width}px)`;
    }
    setDisplayPairCount(displayPairCount + 1);
    setSelectedPairs(displayPairCount);
    updateOption(item, selected, pairGenerator.getEndOfPairDisplay() === 1);
    setTimeout(() => {
      const Width = 0;
      boxRef.current.style.transform = `translateX(${Width}px)`;
    }, 300);
  };

  const longestWordLength = (text) => {
    const words = text.split(' ');
    let maxLength = 0;
    for (let i = 0; i < words.length; i++) {
      if (words[i].length > maxLength) {
        maxLength = words[i].length;
      }
    }
    return maxLength;
  };

  const calibrateFont = (text, imageUrl) => {
    let styleclass;
    const textlength = text.length;
    if (imageUrl !== null) {
      if (textlength <= 20) {
        styleclass = `${styles.descBig}`;
      } else if ((textlength > 20 && textlength <= 60)) {
        styleclass = `${styles.descMedium}`;
      } else if ((textlength > 60)) {
        styleclass = `${styles.descSmall}`;
      }
    } else if (textlength <= 20) {
      styleclass = `${styles.descBigWoImage}`;
    } else if (textlength > 20 && textlength <= 60) {
      styleclass = `${styles.descMediumWoImage}`;
    } else if ((textlength > 60)) {
      styleclass = `${styles.descSmallWoImage}`;
    }
    const maxWordLength = longestWordLength(text);
    if (maxWordLength > 24 && textlength > 60) {
      styleclass = `${styles.descFontBigWord}`;
    } else if (maxWordLength > 24 && textlength <= 60) {
      styleclass = `${styles.descMedium}`;
    }
    return styleclass;
  };
  const calibrateHeaderFont = (textlength) => {
    let styleclass;
    if (textlength < 80) {
      styleclass = `${styles.tqTextLarge}`;
    } else if (textlength > 80 && textlength < 100) {
      styleclass = `${styles.tqTextMedium}`;
    } else if (textlength > 100) {
      styleclass = `${styles.tqTextSmall}`;
    }
    return styleclass;
  };

  const calibrateTextBlock = (textlength, imageUrl) => {
    let styleclass = `${styles.textBlock}`;
    if (imageUrl !== null) {
      // Bug 26901: Items are not being sized the same during smash display
      if ((textlength > 90 && textlength < 120)) {
        styleclass = `${styles.textBlock}`;
      } else if ((textlength >= 120)) {
        styleclass = `${styles.textBlock}`;
      }
    }
    return styleclass;
  };

  const handleCardClick = (cardIndex, selectedItem) => {
    pairGenerator.handlePick(cardIndex);
    toggleNext(currentSmashPair, selectedItem);
    getSmashPair();
  };

  useEffect(() => {
    setInitialTick(getTicks());
    pairGenerator.initRanking(smashItemList);
    getSmashPair();
  }, []);

  return (
    // Issue 32288: User is getting blank screen before moving to Result Screen
    <>
      {(!isEmpty(currentSmashPair?.item1) && !isEmpty(currentSmashPair?.item2)) && (
        <div className={styles.thankyouBlock}>
          <div className={styles.smashDesc}>
            <h3
              style={{ color: customTheme?.smashText }}
              className={calibrateHeaderFont(startMessage?.length || 0)}
              ref={el => {
                if (el && customTheme?.smashText) {
                  el.style.setProperty('--underlineColor', customTheme?.smashText);
                }
              }}
            >
              {startMessage}
            </h3>
            <h5
              className={styles.emailText}
              style={{ color: customTheme?.smashText }}
            >
              Select One
            </h5>
          </div>

          <div className={styles.selectionBox} ref={boxRef}>
            <div className={styles.itemContainer}>
              <div
                role="presentation"
                className={styles.card}
                style={{ background: customTheme?.cardColor }}
                onClick={() => handleCardClick(-1, 'item1', 1)}
              >
                <div className={styles.imgAndPContainer}>
                  {currentSmashPair?.item1.imageURL && (
                    <div className={styles.imgBlock}>
                      {/* User Story 31089: Maintain aspect ratio of Square images  */}
                      <img
                        src={currentSmashPair?.item1.imageURL}
                        alt="img"
                        className={
                          (!currentSmashPair?.item1.smashItemText
                          && !currentSmashPair?.item2.smashItemText)
                        || (!currentSmashPair?.item1.smashItemText
                          && currentSmashPair?.item2.smashItemText)
                            ? styles.bothImg
                            : currentSmashPair?.item1.smashItemText
                              ? styles.img
                              : styles.ImgText
                        }
                      />
                    </div>
                  )}
                  {currentSmashPair?.item1.smashItemText && (
                    <div
                      className={calibrateTextBlock(
                      currentSmashPair?.item1.smashItemText.length,
                      currentSmashPair?.item1.imageURL
                      )}
                    >
                      <p
                        style={{ color: customTheme?.cardText }}
                        className={calibrateFont(currentSmashPair?.item1.smashItemText,
                          currentSmashPair?.item1.imageURL)}
                      >
                        {currentSmashPair?.item1.smashItemText}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.buttonGroupWithinCards}>
                  <button
                    ref={el => {
                      if (el) {
                        el.style.setProperty('background-color', customTheme?.button, 'important');
                      }
                    }}
                    type="button"
                    style={{ color: customTheme?.buttonText }}
                    className={cx(styles.thisAndThatButton, 'waves-effect waves-light btn-small')}
                  >
                    This

                  </button>
                  <button
                    type="button"
                    ref={el => {
                      if (el) {
                        el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                      }
                    }}
                    style={{ color: customTheme?.buttonText }}
                    className={cx(styles.thisAndThatButtonIcon, 'waves-effect waves-light btn-small')}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <h4
                style={{ color: customTheme?.smashText }}
                className={styles.or}
              >
                OR
              </h4>
              <div
                role="presentation"
                className={styles.card}
                style={{ background: customTheme?.cardColor }}
                onClick={() => handleCardClick(1, 'item2', 1)}
              >
                <div className={styles.imgAndPContainer}>
                  {currentSmashPair?.item2.smashItemText && (
                    <div
                      className={calibrateTextBlock(
                      currentSmashPair?.item2.smashItemText.length,
                      currentSmashPair?.item2.imageURL
                      )}
                    >
                      <p
                        style={{ color: customTheme?.cardText }}
                        className={calibrateFont(currentSmashPair?.item2.smashItemText,
                          currentSmashPair?.item2.imageURL)}
                      >
                        {currentSmashPair?.item2.smashItemText}
                      </p>
                    </div>
                  )}
                  {currentSmashPair?.item2.imageURL && (
                    <div className={styles.imgBlock}>
                      {/* User Story 31089: Maintain aspect ratio of Square images  */}
                      <img
                        src={currentSmashPair?.item2.imageURL}
                        alt="img"
                        className={
                          (!currentSmashPair?.item1.smashItemText
                          && !currentSmashPair?.item2.smashItemText)
                        || (currentSmashPair?.item1.smashItemText
                          && !currentSmashPair?.item2.smashItemText)
                            ? styles.bothImg
                            : currentSmashPair?.item2.smashItemText
                              ? styles.img
                              : styles.imgText
                        }
                      />
                    </div>
                  )}
                </div>
                <div className={styles.buttonGroupWithinCards}>
                  <button
                    type="button"
                    ref={el => {
                      if (el) {
                        el.style.setProperty('background-color', customTheme?.button, 'important');
                      }
                    }}
                    style={{ color: customTheme?.buttonText }}
                    className={cx(styles.thisAndThatButton, 'waves-effect waves-light btn-small')}
                  >
                    That

                  </button>
                  <button
                    ref={el => {
                      if (el) {
                        el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                      }
                    }}
                    type="button"
                    style={{ color: customTheme?.buttonText }}
                    className={cx(styles.thisAndThatButtonIcon, 'waves-effect waves-light btn-small')}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

SmashPairs.propTypes = {
  callback: PropTypes.func.isRequired,
  setSelectedPairs: PropTypes.func.isRequired,
  isPreview: PropTypes.bool.isRequired,
  startMessage: PropTypes.string.isRequired,
  smashItemList: PropTypes.array.isRequired,
  sessionId: PropTypes.string.isRequired,
  smashId: PropTypes.string.isRequired,
  customTheme: PropTypes.object.isRequired
};

export default SmashPairs;
