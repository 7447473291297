export const SmashStatus = Object.freeze({
  Active: 'active',
  Draft: 'draft',
  Complete: 'complete',
  Inactive: 'inactive',
  Archive: 'archive',
});
export const UserTier = Object.freeze({
  Free: 0,
  Silver: 1,
  Gold: 2,
  Platinum: 3,
  LifeTime: 4
});
