import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { storeUser, userSignedOut } from '../actions/authActions';
import cookieService from '../cookieService';

function AuthProvider({
  user,
  userManager: manager, store, children, setGlobalUserProps
}) {
  const userManager = useRef();
  useEffect(() => {
    userManager.current = manager;
    setGlobalUserProps(user);
    const onUserLoaded = (user) => {
      console.log(`user loaded: ${user}`);
      cookieService.set('user', JSON.stringify(user));
      store.dispatch(storeUser(user));
    };

    const onUserUnloaded = () => {
      cookieService.remove('user');
      sessionStorage.clear();
      store.dispatch(userSignedOut());
      console.log('user unloaded');
    };

    const onAccessTokenExpiring = () => {
      cookieService.remove('user');
      sessionStorage.clear();
      store.dispatch(userSignedOut());
      console.log('user token expiring');
    };

    const onAccessTokenExpired = () => {
      cookieService.remove('user');
      sessionStorage.clear();
      store.dispatch(userSignedOut());
      console.log('user token expired');
    };

    const onUserSignedOut = () => {
      cookieService.remove('user');
      sessionStorage.clear();
      store.dispatch(userSignedOut());
      console.log('user signed out');
    };

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded);
    userManager.current.events.addUserUnloaded(onUserUnloaded);
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.current.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.current.events.removeUserLoaded(onUserLoaded);
      userManager.current.events.removeUserUnloaded(onUserUnloaded);
      userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring);
      userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.current.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [manager, user]);

  return (
    React.Children.only(children)
  );
}

const mapStateToProps = (currState) => currState.auth;
export default connect(mapStateToProps)(AuthProvider);
