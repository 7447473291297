/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import ListItems from 'components/shared/ListItems';
import PropTypes from 'prop-types';
import Api from 'api';
import cx from 'classnames';
import styles from './Results.module.css';
import postData from '../../api/webhook';

function Results({
  // eslint-disable-next-line react/prop-types
  smashId, sessionId, showSmashResult, email, redirectUrl, collectEmail,
  smashEndMessage, firstName, lastName, phone, customTheme
}) {
  const h3Ref = useRef();
  const [loading, setLoading] = useState(true);
  const [newURL, setNewURL] = useState('');
  const [yourFive, setYourFive] = useState([]);
  const [otherFive, setOtherFive] = useState([]);
  const showOtherFive = showSmashResult;
  const hiddenButtonRef = useCallback(anchorRef => {
    if ((anchorRef !== null) && newURL) {
      anchorRef.click();
    }
  }, [newURL]);

  //  User Story 33781: Pass collected contact data in the URL redirect
  const getParticipantContactData = (winItem) => {
    const data = {
      fn: firstName,
      ln: lastName,
      em: email,
      ph: phone
    };
    const params = data;
    const queryStringArray = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        queryStringArray.push(`${key}=${params[key]}`);
      }
    });
    let queryString = queryStringArray.join('&');
    if (queryString) {
      queryString = `&${queryString}`;
    } else {
      queryString = '';
    }
    if (redirectUrl) {
      const url = `${redirectUrl}?ti=${winItem}${queryString}`;
      const encoded = encodeURI(url);
      setNewURL(encoded);
    }
  };

  useEffect(() => {
    Api.getSmashExecutionResults(smashId, sessionId).then(({ data }) => {
      const { participantsTop5, allParticipantsTop5 } = data;
      setYourFive(participantsTop5);
      setOtherFive(allParticipantsTop5);
      /* Bug 40515: When using a redirect link in the configure smash settings,
        Ranksmash no longer passes the first item if there is No Description. */
      getParticipantContactData(participantsTop5[0].item.imageTag);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    if (!yourFive.length) return;
    // Issue 30807: Webhook not triggering
    const webhookInput = {
      SmashId: smashId,
      SessionId: sessionId,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone
    };
    postData(webhookInput).catch(error => {
      console.log('error', error);
    });
  }, [yourFive]);

  if (loading) {
    return <div className="loader"></div>;
  }
  // eslint-disable-next-line react/no-multi-comp
  function renderSmashLink(text, style = cx(styles.link)) {
    return (
      <a
        className={style}
        style={{ color: customTheme?.smashText }}
        // Bug 29404: "Create your own" not creating new account creation flow
        href="/create">
        {text}
      </a>
    );
  }

  return (
    <>
      <div className={styles.thankyouBlock}>
        <>
          <h3
            className={cx(styles.tqText, styles.resultAndComparisonText)}
            style={{ color: customTheme?.smashText }}
            ref={el => {
              if (el && customTheme?.smashText) {
                el.style.setProperty('--underlineColor', customTheme?.smashText);
              }
            }}
          >
            Result &
            <br />
            Comparison
          </h3>
        </>
        <h3
          style={{ color: customTheme?.smashText }}
          className={cx(styles.tqText)}
        >
          {smashEndMessage}
        </h3>
        <div className={styles.block}>
          <ListItems
            list={yourFive}
            customTheme={customTheme}
            // User Story 26900: Top results says "top 5 results" even if there are only 4 items
            label={yourFive.length < 5 ? `Your top ${yourFive.length} list`
              : 'Your top 5 list'} />
          {showOtherFive && (
            <ListItems
              list={otherFive}
              label="How you compare"
              customTheme={customTheme}
            />
          )}
        </div>
        <div className={styles.footer}>
          {/* Issue 30804: Create your own button doesn't work */}
          <a href="/Create">
            <div className={styles.buttonGroup}>
              <button
                ref={el => {
                  if (el) {
                    el.style.setProperty('background-color', customTheme?.button, 'important');
                    // 41165: Apply buttonText value entered by user to the buttons on result component
                    el.style.setProperty('color', customTheme?.buttonText);
                  }
                }}
                type="button"
                className={cx(styles.next, 'waves-effect waves-light btn-large')}
              >
                CREATE YOUR OWN
              </button>
              <button
                ref={el => {
                  if (el) {
                    el.style.setProperty('background-color', customTheme?.buttonSplit, 'important');
                    // 41165: Apply buttonText value entered by user to the buttons on result component
                    el.style.setProperty('color', customTheme?.buttonText);
                  }
                }}
                type="button"
                className={cx(
                  styles.nextIcon,
                  'waves-effect waves-light btn-large'
                )}
              >
                <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
              </button>
            </div>
          </a>
          <a
            ref={hiddenButtonRef}
            href={newURL}
            target="_blank"
            rel="noopener noreferrer"
            hidden
          >
            Global Redirect URL
          </a>
        </div>
      </div>
      {/* {redirectUrl && redirect()} */}
    </>
  );
}

Results.propTypes = {
  smashId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  customTheme: PropTypes.object.isRequired,
  showSmashResult: PropTypes.bool.isRequired,
  email: PropTypes.string,
  smashEndMessage: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
};

Results.defaultProps = {
  email: '',
  smashEndMessage: '',
  firstName: '',
  lastName: '',
  phone: ''
};

export default Results;
